import { Component, OnInit, Input } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {UserprogramsComponent} from '../userprograms/userprograms.component';
import {FormdataService} from '../shared/formdata.service';

export interface DialogData {
  program: string;
  time: string;
}

@Component({
  selector: 'app-customerdetails',
  templateUrl: './customerdetails.component.html',
  styleUrls: ['./customerdetails.component.css']
})
export class CustomerdetailsComponent implements OnInit {

  post = '';
  parentPosts: any[]=[];

  count: number = 0;

  constructor(
    public dialog: MatDialog,
  ) { }

  program: string;
  time: string;

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(UserprogramsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.program = result;
    });
  }

  ngOnInit(): void {
  }

  showMe:boolean = true;
  showMe1:boolean = true;
  // hideMe:boolean = false;

  All() {
    this.showMe1 = true;
    this.showMe = true;
    // this.hideMe = !this.hideMe;
  }

  toggleTag() {
    this.showMe1 = false;
    this.showMe = true;
    // this.hideMe = !this.hideMe;
  }

  toggleTag1() {
    this.showMe = false;
    this.showMe1 = true;
  }

  AddPost(post){
    console.log(this.post);
    this.parentPosts.push(post);
  }

  display = false;

  Display() {
    this.display = true;
  }

  enterName1 = "";
  enterName2 = "";
  parentData1 = "";
  parentData2 = "";

  TransferData() {
    this.parentData1 = this.enterName1;
    this.parentData2 = this.enterName2;
  }

  labelPosition: 'before' | 'after' = 'after';

}
