<div class="w3-row background toolbar">
  <div class="w3-col" style="width:20%">
    <div class="centered">
      <circle-progress class="steps"
                       [title]="'Step'" [titleFontSize]="18"
                       [subtitle]="'2/3'"
                       [subtitleFontSize]="20"
                       [percent]="66"
                       [radius]="40"
                       [space]="-10"
                       [outerStrokeGradient]="true"
                       [outerStrokeWidth]="10"
                       [outerStrokeColor]="'rgb(236,212,180)'"
                       [outerStrokeGradientStopColor]="'rgba(177,141,118,1)'"
                       [innerStrokeColor]="'rgba(231, 232, 234, 1)'"
                       [innerStrokeWidth]="10"
                       [showUnits]="false"
                       [showBackground]="false"
                       [clockwise]="true"
                       [startFromZero]="false"
      ></circle-progress>
    </div>
  </div>
  <div class="w3-col topmar" style="width:60%">
    <div class="row centered">
      <div class="bkde">Customer Details</div>
      <div><br></div>
      <div class="centered">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <span class="title">Guest1 :</span>
            <app-customername1 [parentData1]="parentData1"></app-customername1>
          </mat-expansion-panel-header>
          <div><br></div>
          <div class="centered">
            <input class="input" type="text" id="name" placeholder="{{'Name' | translate}}" name="pswd"
                   [(ngModel)]="parentData1">
          </div>
          <div><br></div>
          <div><br></div>
          <mat-radio-group [(ngModel)]="labelPosition" class="lefty centered">
            <input type="radio" name="gender" class="marginn" value="after"><label class="textex">Male</label>
            <input type="radio" name="gender" class="marginn" value="before"><label class="textex">Female</label>
          </mat-radio-group>
          <div><br></div>
          <div><br></div>
          <div class="lefty row textex centered">Please Select a programs</div>
          <div><br></div>
          <div><br></div>
<!--                          <app-userprograms [childPosts]="parentPosts"></app-userprograms>-->
          <button mat-stroked-button (click)="openDialog()" class="batton">
            <div class="centered">
              <mat-icon>add_circle</mat-icon>
              <div class="textex">ADD PROGRAMS</div>
            </div>
          </button>
        </mat-expansion-panel>
      </div>
      <div><br></div>
    </div>
    <div *ngIf="display">
      <div class="centered">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <span class="title">Guest{{count + 1}} :</span>
            <app-customername1 [parentData2]="parentData2"></app-customername1>
          </mat-expansion-panel-header>
          <div><br></div>
          <div class="centered">
            <input class="input" type="text" id="name1" placeholder="{{'Name' | translate}}" name="pswd"
                   [(ngModel)]="parentData2">
          </div>
          <div><br></div>
          <div><br></div>
          <mat-radio-group [(ngModel)]="labelPosition" class="lefty centered">
            <input type="radio" name="gender" class="marginn" value="after"><label class="textex">Male</label>
            <input type="radio" name="gender" class="marginn" value="before"><label class="textex">Female</label>
          </mat-radio-group>
          <div><br></div>
          <div><br></div>
          <div class="lefty row textex centered">Please Select a programs</div>
          <div><br></div>
          <div><br></div>
<!--                          <app-userprograms [childPosts]="parentPosts"></app-userprograms>-->
          <button mat-stroked-button (click)="openDialog()" class="batton">
            <div class="centered">
              <mat-icon>add_circle</mat-icon>
              <div class="textex">ADD PROGRAMS</div>
            </div>
          </button>
        </mat-expansion-panel>
      </div>
      <div><br></div>
    </div>
    <div class="row centered">
      <button mat-stroked-button class="batton1" (click)="count = count + 1" (click)="Display()">
        <div class="centered">
          <mat-icon class="marginn">person_add</mat-icon>
          <div class="marginn"></div>
          <div class="textex1">ADD GUEST</div>
        </div>
      </button>
      <div><br></div>
      <div class="centered toolbar2">
        <button class="button1" routerLink="/customer" mat-button>{{'CONTINUE' | translate}}</button>
      </div>
    </div>
  </div>
  <div class="w3-col topba centered" style="width:20%">
    <div>
      <h2 class="text">Customer Details</h2>
      <p class="text1">Next : transaction details</p>
    </div>
  </div>
</div>
