import {TranslateState} from './translate.state';
import {headerEn} from '../languages/header/headerEn.js';
import {aboutEn} from '../languages/About/aboutEn.js';
import {overviewEn} from '../languages/Branches/overviewEn.js';
import {contactUsEn} from '../languages/contactUs/contactUsEn.js';
import {signatureEn} from '../languages/SpaMenu/signature/signatureEn.js';
import {bodyEn} from '../languages/SpaMenu/body/bodyEn.js';
import {facialEn} from '../languages/SpaMenu/facial/facialEn.js';
import {expressEn} from '../languages/SpaMenu/express/expressEn.js';
import {hairAndNailsEn} from '../languages/SpaMenu/hairAndNails/hairAndNailsEn.js';
import {journeyEn} from '../languages/SpaMenu/journey/journeyEn.js';
import {etcEn} from '../languages/SpaMenu/etc/etcEn.js';
import {promotionsEn} from '../languages/promotions/promotionsEn.js';

export const TranslateInit: TranslateState = {
  promotions: promotionsEn,
  etc: etcEn,
  journey: journeyEn,
  hairAndNails: hairAndNailsEn,
  express: expressEn,
  facial: facialEn,
  menuBody: bodyEn,
  signature: signatureEn,
  contactUs: contactUsEn,
  branch: overviewEn,
  about: aboutEn,
  header: headerEn,
  error: '',
  th: false
};
