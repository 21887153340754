import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-customername1',
  templateUrl: './customername1.component.html',
  styleUrls: ['./customername1.component.css']
})
export class Customername1Component implements OnInit {

  @Input() parentData: string;
  @Input() parentData1: string;
  @Input() parentData2: string;

  constructor() { }

  ngOnInit(): void {
  }

}
