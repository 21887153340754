import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, selectAppState } from '../../state/app.state';

@Component({
  selector: 'app-spa48program',
  templateUrl: './spa48program.component.html',
  styleUrls: ['./spa48program.component.css']
})
export class Spa48programComponent implements OnInit {

  lang;

  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.app$.subscribe((state) => {
      this.app = state.translate.etc;
    });
  }

  // ngOnInit() {
  //   this.lang = localStorage.getItem('lang') || 'en';
  // }
  changeLang(lang){
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

}
