export const hairAndNailsEn = {
  title: "Hair Treatment - Manicures Pedicures",
  courses: [
    {
      course: "NAIL EXPRESS",
      time: "30 MINUTES",
      price: "490 THB"
    },
    {
      course: "GENTLEMAN'S MANICURE",
      time: "30 MINUTES",
      price: "590 THB"
    },
    {
      course: "GENTLEMAN'S PEDICURE",
      time: "30 MINUTES",
      price: "590 THB"
    },
    {
      course: "CLASSIC MANICURE",
      time: "45 MINUTES",
      price: "890 THB"
    },
    {
      course: "CLASSIC PEDICURE",
      time: "45 MINUTES",
      price: "890 THB"
    },
    {
      course: "DELUXE PEDICURE",
      time: "60 MINUTES",
      price: "1,290 THB"
    },
    {
      course: "DELUXE MANICURE",
      time: "60 MINUTES",
      price: "1,290 THB"
    },
    {
      course: "HAIR TREATMENT AND BLOW DRY",
      time: "60 MINUTES",
      price: "1,290 THB"
    },

  ]
}
