<div class="container-fluid background justify-content-center text-center">
  <div class="w3-row">
    <div class="space"></div>
    <div class="w3-col w3-container">
      <div class="title">{{app.translate.contactUs.title}}</div>
      <div class="container">
        <img class="titleImage" src="/assets/spaimg/new-title23-bg.png" alt="promo05">
      </div>
    </div>
  </div>

  <!-- Start S42 -->
  <div class="branchContainer">
    <div *ngFor="let s42 of S42s; let i=index">
      <div class="w3-row branchRow">
        <div class="w3-container w3-quarter">
          <img src="{{app.translate.contactUs.branches[0].image}}" class="branchImage" alt="Suk42img">
        </div>
        <div class="w3-container w3-half">
          <div class="branchInfoContainer">
            <h5 class="w3-large branchName1">{{app.translate.contactUs.SenseCeraSpa}}</h5>
            <span class="w3-large branchName">{{app.translate.contactUs.branches[0].name}}</span>
            <div class="onl addressContainer">
              <div class="addressInfo">{{app.translate.contactUs.branches[0].address1}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[0].address2}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[0].phone}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[0].email}}</div>
            </div>
          </div>
        </div>
        <div class="w3-container w3-quarter">
          <img src="{{app.translate.contactUs.branches[0].map}}" class="mapImage" alt="Suk42map"
               (click)="openDialog1('0ms', '0ms')">
        </div>
      </div>
      <img class="img horizontal-line" src="./assets/component/icons/decline.png" alt="">
    </div>
  </div>
  <!--  End of S42 -->

  <!--  Start queen -->
  <div class="branchContainer">
    <div *ngFor="let queen of Queens; let i=index">
      <div class="w3-row branchRow">
        <div class="w3-container w3-quarter">
          <img src="{{app.translate.contactUs.branches[1].image}}" class="branchImage queen-image" alt="Queenimg">
        </div>
        <div class="w3-container w3-half">
          <div class="branchInfoContainer">
            <h5 class="w3-large branchName1">{{app.translate.contactUs.SenseCeraSpa}}</h5>
            <span class="w3-large black branchName">{{app.translate.contactUs.branches[1].name}}</span>
            <div class="onl addressContainer">
              <div class="addressInfo">{{app.translate.contactUs.branches[1].address1}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[1].address2}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[1].phone}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[1].email}}</div>
            </div>
          </div>
        </div>
        <div class="w3-container w3-quarter">
          <img src="{{app.translate.contactUs.branches[1].map}}" class="mapImage" alt="Queenmap"
               (click)="openDialog2('0ms', '0ms')">
        </div>
      </div>
      <img class="img horizontal-line" src="./assets/component/icons/decline.png" alt="">
    </div>
  </div>
  <!-- End of queen -->

  <!--  Start S48 -->
  <div class="branchContainer">
    <div *ngFor="let s48 of S48s; let i=index">
      <div class="w3-row branchRow">
        <div class="w3-container w3-quarter">
          <img src="{{app.translate.contactUs.branches[2].image}}" class="branchImage" alt="Suk48img">
        </div>
        <div class="w3-container w3-half">
          <div class="branchInfoContainer">
            <h5 class="w3-large branchName1">{{app.translate.contactUs.SenseCeraSpa}}</h5>
            <span class="w3-large black branchName">{{app.translate.contactUs.branches[2].name}}</span>
            <div class="onl addressContainer">
              <div class="addressInfo">{{app.translate.contactUs.branches[2].address1}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[2].address2}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[2].phone}}</div>
              <div class="addressInfo">{{app.translate.contactUs.branches[2].email}}</div>
            </div>
          </div>
        </div>
        <div class="w3-container w3-quarter">
          <img src="{{app.translate.contactUs.branches[2].map}}" class="mapImage" alt="Suk48map"
               (click)="openDialog3('0ms', '0ms')">
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <div class="hide"><br></div>
  <!--  End S48 -->
</div>
