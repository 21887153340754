import {Component, OnInit} from '@angular/core';
import {bodyEn} from '../../../state/languages/SpaMenu/body/bodyEn.js';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {
  app: any;

  constructor() {
    this.app = bodyEn;
  }

  ngOnInit(): void {
  }

}
