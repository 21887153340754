import {Component, OnInit} from '@angular/core';
import {hairAndNailsEn} from '../../../state/languages/SpaMenu/hairAndNails/hairAndNailsEn.js';

@Component({
  selector: 'app-hair-and-nails',
  templateUrl: './hair-and-nails.component.html',
  styleUrls: ['./hair-and-nails.component.css']
})
export class HairAndNailsComponent implements OnInit {

  app: any;

  constructor() {
    this.app = hairAndNailsEn;
  }

  ngOnInit(): void {
  }

}
