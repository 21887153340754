import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-landing',
  templateUrl: './layout-landing.component.html',
  styleUrls: ['./layout-landing.component.css']
})
export class LayoutLandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
