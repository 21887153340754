export const etcTh = {
  branch: {
    common: "เมนู เซนส์ เซร่า ไซมิส สปา",
    s42: "ไซมิส สุขุมวิท 42",
    queen: "ไซมิส เอ๊กซ์คลูซีพ ควีนส์",
    s48: "ไซมิส สุขุมวิท 48"
  },
  titleBar: "SENSE CERA SPA PROGRAMS",
  navBar: {
    signature: "SIGNATURE",
    journey: "JOURNEY",
    body: "BODY",
    facial: "FACIAL",
    express: "EXPRESS",
    hairAndNails: "HAIR & NAILS"
  }
}
