import { QpromotionsComponent } from './../qpromotions/qpromotions.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { PromosComponent } from '../promos/promos.component';
import { AboutpageComponent } from '../aboutpage/aboutpage.component';
import { LocationsComponent } from '../branches/locations.component';
import { ContactusComponent } from '../contactus/contactus.component';
import { ForminputComponent } from '../forminput/forminput.component';
import { CustomerdetailsComponent } from '../customerdetails/customerdetails.component';
import { Spa42programComponent } from '../SpaMenu/S42Program/spa42program.component';
import { Spa48programComponent } from '../SpaMenu/S48Program/spa48program.component';
import { SpaqueenprogramComponent } from '../SpaMenu/QueenProgram/spaqueenprogram.component';
import { LandingpageComponent } from '../landingpage/landingpage.component';
import { PromolandingComponent } from '../promolanding/promolanding.component';
import { QueenpromoComponent } from '../queenpromo/queenpromo.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutpageComponent },
  { path: 's42promotion', component: PromosComponent },
  { path: 'branches', component: LocationsComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'booking', component: ForminputComponent },
  { path: 'customer', component: CustomerdetailsComponent },
  { path: 'spa42menu', component: Spa42programComponent},
  { path: 'spa48menu', component: Spa48programComponent},
  { path: 'spaqueenmenu', component: SpaqueenprogramComponent},
  { path: 'publishing', component: LandingpageComponent},
  { path: 'promotion', component: PromolandingComponent},
  { path: 'qpromotion', component: QpromotionsComponent},
  { path: 'queenpromo', component: QueenpromoComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [HomeComponent];
