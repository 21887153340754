<div class="container">

  <div class="course">{{app.title.toUpperCase()}}</div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.courses[0].course}}</div>
      <div class="treatmentPrice">{{app.courses[0].cost}}</div>
    </div>

    <p class="description">
      {{app.courses[0].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div>
    <div class="flexTitle">
      <div class="treatmentName">{{app.courses[1].course}}</div>
      <div class="treatmentPrice">{{app.courses[1].cost}}</div>
    </div>

    <p class="description">
      {{app.courses[1].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div>
    <div class="flexTitle">
      <div class="treatmentName">{{app.courses[2].course}}</div>
      <div class="treatmentPrice">{{app.courses[2].cost}}</div>
    </div>

    <p class="description">
      {{app.courses[2].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div>
    <div class="flexTitle">
      <div class="treatmentName">{{app.courses[3].course}}</div>
      <div class="treatmentPrice">{{app.courses[3].cost}}</div>
    </div>

    <p class="description">
      {{app.courses[3].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div>
    <div class="flexTitle">
      <div class="treatmentName">{{app.courses[4].course}}</div>
      <div class="treatmentPrice">{{app.courses[4].cost}}</div>
    </div>

    <p class="description">
      {{app.courses[4].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div>
    <img class="singleImage" src="../../../../assets/component/spamenu/spamenu3.png" alt="linedec3">
  </div>

  <div class="flexImage">
    <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu4.png" alt="spamenuimg1">
    <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu5.png" alt="spamenuimg1">
  </div>

</div>

<div class="container">

  <div class="course">{{app.title2.toUpperCase()}}</div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.bathing[0].course}}</div>
      <div class="treatmentPrice">{{app.bathing[0].cost}}</div>
    </div>

    <p class="description">
      {{app.bathing[0].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.bathing[1].course}}</div>
      <div class="treatmentPrice">{{app.bathing[1].cost}}</div>
    </div>

    <p class="description">
      {{app.bathing[1].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.bathing[2].course}}</div>
      <div class="treatmentPrice">{{app.bathing[2].cost}}</div>
    </div>

    <p class="description">
      {{app.bathing[2].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div>
    <img class="singleImage" src="../../../../assets/component/spamenu/spamenu6.png" alt="linedec3">
  </div>
</div>
