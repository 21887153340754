<div class="container">

  <div class="course">{{app.translate.express.title.toUpperCase()}}</div>
  
  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.express.courses[0].course}}</div>
      <div class="treatmentPrice">{{app.translate.express.courses[0].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.express.courses[0].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">

    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.express.courses[1].course}}</div>
      <div class="treatmentPrice">{{app.translate.express.courses[1].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.express.courses[1].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.express.courses[2].course}}</div>
      <div class="treatmentPrice">{{app.translate.express.courses[2].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.express.courses[2].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div>
    <img class="singleImage" src="../../../../assets/component/spamenu/spamenu13.png" alt="linedec3">
  </div>
</div>
