import {createReducer, on} from '@ngrx/store';
import {TranslateInit} from './translate.init';
import {headerTh} from '../languages/header/headerTh.js';
import {headerEn} from '../languages/header/headerEn.js';
import {aboutTh} from 'src/app/state/languages/About/aboutTh.js';
import {aboutEn} from 'src/app/state/languages/About/aboutEn.js';
import {overviewEn} from 'src/app/state/languages/Branches/overviewEn.js';
import {overviewTh} from 'src/app/state/languages/Branches/overviewTh.js';
import {contactUsTh} from '../languages/contactUs/contactUsTh.js';
import {contactUsEn} from '../languages/contactUs/contactUsEn.js';
import {signatureTh} from '../languages/SpaMenu/signature/signatureTh.js';
import {signatureEn} from '../languages/SpaMenu/signature/signatureEn.js';
import {bodyTh} from '../languages/SpaMenu/body/bodyTh.js';
import {bodyEn} from '../languages/SpaMenu/body/bodyEn.js';
import {facialTh} from '../languages/SpaMenu/facial/facialTh.js';
import {facialEn} from '../languages/SpaMenu/facial/facialEn.js';
import {expressTh} from '../languages/SpaMenu/express/expressTh.js';
import {expressEn} from '../languages/SpaMenu/express/expressEn.js';
import {hairAndNailsTh} from '../languages/SpaMenu/hairAndNails/hairAndNailsTh.js';
import {hairAndNailsEn} from '../languages/SpaMenu/hairAndNails/hairAndNailsEn.js';
import {journeyTh} from '../languages/SpaMenu/journey/journeyTh.js';
import {journeyEn} from '../languages/SpaMenu/journey/journeyEn.js';
import {etcTh} from '../languages/SpaMenu/etc/etcTh.js';
import {etcEn} from '../languages/SpaMenu/etc/etcEn.js';
import {promotionsTh} from '../languages/promotions/promotionsTh.js';
import {promotionsEn} from '../languages/promotions/promotionsEn.js';
import {
  translateTH,
  translateEN
} from './translate.actions';

export const translateReducer = createReducer(
  TranslateInit,

  on(translateTH, (state) => ({
    
    ...state,
    promotions:promotionsTh,
    etc:etcTh,
    journey:journeyTh,
    hairAndNails:hairAndNailsTh,
    express:expressTh,
    facial:facialTh,
    menuBody:bodyTh,
    signature:signatureTh,
    contactUs:contactUsTh,
    branch:overviewTh,
    about:aboutTh,
    header: headerTh,
    th: true
  })),

  on(translateEN, (state) => ({
    ...state,
    promotions:promotionsEn,
    etc:etcEn,
    journey:journeyEn,
    hairAndNails:hairAndNailsEn,
    express:expressEn,
    facial:facialEn,
    menuBody:bodyEn,
    signature:signatureEn,
    contactUs:contactUsEn,
    branch:overviewEn,
    about:aboutEn,
    header: headerEn,
    th: false
  })),
);
