export const signatureEn = {
  title: "Signature Programs",
  courses: [
    {
      course: "PINDA SWEDANA MASSAGE",
      cost: "90/120 min (2,160/2,640 THB)",
      detail: "Pinda Swedana is a traditional Ayurvedic therapy with profound healing and renewal properties. In this therapy, a warmed cloth, prepared with herbs, milk and rice, massages your body. The heat of the herbal compress opens the body’s energy channels and enables the herbalized milk to penetrate deeply into underlying tissue. Pinda Swedana enhances circulation, improves muscle tone, strengthens the nervous system, and restores vitality to the entire body.",
    },
    {
      course: "SIAM BLENDED MASSAGE",
      cost: "90/120 min (2,160/2,640 THB)",
      detail: "Experience the liberating impacts of a combination of four massage techniques: Thai, Swedish, Shiatsu and Lomi Lomi. The deeper pressure of Eastern massage paired with soothing Western movements deeply relax and unfold a wonderfully therapeutic overall effect. It will leave you feeling younger, happier and readier than ever to take on the world.",
    },
    {
      course: "AYURVEDIC ABHYANGA MASSAGE",
      cost: "90/120 min (2,160/2,640 THB)",
      detail: "A blissfully relaxing massage with warm oil. At once purifying and invigorating traditional massage techniques are complemented with acupressure applied to the body’s marma points, or junctions where different tissues meet, to promote deep healing. As the warm oil harmonizes, the whole body is rebalanced and vitality is regenerated. ",
    },
  ],
};
