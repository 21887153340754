import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectAppState} from '../../state/app.state';
import {translateEN, translateTH} from '../../state/translate/translate.actions';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.css']
})
export class HeaderMobileComponent implements OnInit {

  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.app$.subscribe(state => {
      this.app = state;
    });
  }

  async translate() {
    if (this.app.translate.th) {
      this.store.dispatch(translateEN());
    } else {
      this.store.dispatch(translateTH());
    }
  }
}
