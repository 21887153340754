<div class="w3-row background">
  <div class="w3-col" style="width:5%"><br></div>
  <div class="w3-col">

    <div class="container-lg frame">

      <div class="title"> {{app.translate.about.title}}</div>
      <div class="subTitle"> {{app.translate.about.subtitle}}</div>

      <div class="container-fluid centered">
        <img class="titleShadow" src="/assets/spaimg/new-title1-bg.png" alt="aboutphoto1">
      </div>

      <div class="content section1en" *ngIf="!app.translate.th">
        {{app.translate.about.content.paragraph1en}}
      </div>

      <div class="content section2en" *ngIf="!app.translate.th">
        {{app.translate.about.content.paragraph2en}}
      </div>

      <div class="content" *ngIf="app.translate.th">
        {{app.translate.about.content.paragraph1th}}
      </div>

      <img class="image" src="./assets/component/about/aboutuspic.png" alt="About">
      
      <div class="content section3en" *ngIf="!app.translate.th">
        {{app.translate.about.content.paragraph3en}}
      </div>

      <div class="content section4en" *ngIf="!app.translate.th">
        {{app.translate.about.content.paragraph4en}}
      </div>

      <div class="content bottom" *ngIf="app.translate.th">
        {{app.translate.about.content.paragraph2th}}
      </div>

    </div>
  </div>
  <div class="w3-col" style="width:5%"><br></div>
</div>

<mat-toolbar-row class="gold"></mat-toolbar-row>
