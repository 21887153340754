import { Component, OnInit } from '@angular/core';
import { facialEn } from '../../../state/languages/SpaMenu/facial/facialEn.js';

@Component({
  selector: 'app-facial',
  templateUrl: './facial.component.html',
  styleUrls: ['./facial.component.css']
})
export class FacialComponent implements OnInit {

  app:any;

  constructor() {
    this.app = facialEn;
   }

  ngOnInit(): void {
  }

}
