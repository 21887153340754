
<!-- <div class="BG show">
    <div class="bg1">
            <div class="">
                <img class="promo1" src="/assets/component/landingpage/promo1.png" alt="">
                <img class="promo2" src="/assets/component/landingpage/promo2.png" alt="">
            </div>
    </div>
</div>

<div class="BG1 hide">
    <div class="bg1">
            <div class="">
                <img class="promo1" src="/assets/component/landingpage/promo1.png" alt="">
                <img class="promo2" src="/assets/component/landingpage/promo2.png" alt="">
            </div>
    </div>
</div> -->

<img class="fullw" src="assets/component/promotionApril/S42/vertical/1.png" alt="">
<img class="fullw" src="assets/component/promotionApril/S42/vertical/3.png" alt="">
<img class="fullw" src="assets/component/promotionApril/S42/vertical/phytomer1.png" alt="">
<img class="fullw" src="assets/component/promotionApril/S42/vertical/phytomer2.png" alt="">
<img class="fullw" src="assets/component/promotionApril/S42/vertical/phytomer3.png" alt="">


<!-- <div class="BG1 hide">
    <div>
        <img class="bg2" src="/assets/component/landingpage/spabg1.png" alt="">
            <div class="">
                <div class="contentm">
                    <img class="spalogo1" src="/assets/component/landingpage/spalogo.png" alt="">
                    <div class="location1">at Siamese Exclusive Sukhumvit 42</div>
                    <img class="spabgm hide1" src="/assets/component/landingpage/spabgm1.png" alt="">
                    <img class="spabgm show1" src="/assets/component/landingpage/spabgm.png" alt="">
                    <div class="menu1">
                        <div class="menutext1">Download Spa Menu</div>
                        <div class="centered">
                            <button mat-button class="engver1"><a target="_blank" href="https://anyflip.com/lxfbh/dutp/">English version</a></button>
                            <mat-divider [vertical]="true"></mat-divider>
                            <button mat-button class="japver1"><a target="_blank" href="https://anyflip.com/lxfbh/jaba/">Japan version</a></button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div> -->

