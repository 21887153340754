<section class="banner">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide>
      <img fetchpriority="high" src="./assets/component/banner-img/Banner Design-2.png" style="width:100%">
    </ng-template>
    <ng-template carouselSlide>
      <img fetchpriority="high" src="./assets/component/banner-img/Banner Design-1.png" style="width:100%">
    </ng-template>
    <ng-template carouselSlide>
      <img fetchpriority="high" src="./assets/component/banner-img/Banner Design-3.png" style="width:100%">
    </ng-template>
    <ng-template carouselSlide>
      <img fetchpriority="high" src="./assets/component/banner-img/Banner%20Design-4.png" style="width:100%">
    </ng-template>
    <ng-template carouselSlide>
      <img fetchpriority="high" src="./assets/component/banner-img/Banner%20Design-6.png" style="width:100%">
    </ng-template>
    <ng-template carouselSlide>
      <img fetchpriority="high" src="./assets/component/banner-img/Banner Design-5.png" style="width:100%">
    </ng-template>
  </owl-carousel-o>
</section>
<mat-toolbar-row class="gold"></mat-toolbar-row>
