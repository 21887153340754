export const journeyEn = {
  title: "SENSE JOURNEYS",
  title2: "Bathing Rituals",
  courses: [
    {
      course: "SU  KWAN – JET LAG RECOVERY",
      time: "150 MINUTES",
      price: "3,840 THB",
      cost: "150 MIN  3,840 THB",
      detail: "Su Kwan or Rub Kwan is a traditional welcoming treatment of northern Thai people. The treatment is given to newly-arrived visitors after a long journey to visit friends or family. Its powers work especially well on jet lag. Reducing muscle tension and stress of travel, body and mind are fully reenergized and refreshed. The journey of recovery begins with an Aromatic Bath followed by Body Exfoliation. Next comes an uplifting Jet Lag Massage and the journey concludes with a special Hair Treatment. Thus harmony is orchestrated throughout the body especially including the mind.",
    },
    {
      course: "LANGKA - SUKA",
      time: "180 MINUTES",
      price: "5,040 THB",
      cost: "180 MIN  5,040 THB",
      detail: "Traditional southern Thai long-term care. Start with a Himalayan Salt Sauna and Southern Herbal Body Scrub to gently exfoliate your body. Follow with a Langka-Suka Massage, a special technique that begins by massaging the solar plexus in combination with acupressure holds and warm compresses steeped in a marvelous Thai massage oil formulated from southern Thai herbal extracts blended with black cumin oil, known to stimulate blood flow and relieve muscle tension. Finally, a tailored Facial Treatment lifts and brightens your complexion.",
    },
    {
      course: "CHEEVIT CHEEVA ISAN",
      time: "150 MINUTES",
      price: "3,840 THB",
      cost: "150 MIN  3,840 THB",
      detail: "Your transformative journey begins with Herbal Foot Soak in a concoction of sea salt, green tea and grape that counters inflammation and improves blood circulation. The treatment continues with an Aromatic Bath, a Mulberry Body Scrub with golden silk that deep cleanses, and a White Orchid Body Mask that brightens the face with a new radiance. The following Thai Loincloth Stretching Massage, or Pha-Kao-Ma as it is known locally, is a variation on traditional Thai massage. The therapist uses the loincloth to stretch and knead your body, relieving muscular aches and tiredness without pain or discomfort.",
    },
    {
      course: "PIAMSUK",
      time: "180 MINUTES",
      price: "5,040 THB",
      cost: "180 MIN  5,040 THB",
      detail: "Himalayan Salt Sauna promotes circulation and invigorates. Jasmine Rice Body Scrub, combined with lotus seed pomelo extract and rice bran oil good for the skin, refreshes the senses. Swedish Massage gently disperses physical tension from the body, loosening tight muscles and reducing stiffness. A Foot Massage manipulates points on the feet, balancing energy flow around the body. Conclude with a Moisturizing Facial Treatment  that will make your complexion glow with vitality.",
    },
    {
      course: "SABAI KAI SABAI  JAI ",
      time: "240 MINUTES",
      price: "6,480 THB",
      cost: "240 MIN  6,480 THB",
      detail: "This signature classic relaxing massage promotes a powerful sense of wellbeing. First, your body is refreshed in a Milk Bath that stimulates blood circulation. Next a Body Scrub exfoliates and cleanses your skin. Then a Body Mask moisturizes and smooths, leaving you feeling fresher than ever. A Facial Comforting Treatment nurtures and softens sensitive skin areas while a personalized Hair Treatment does a similar job on your hair.",
    },
  ],
  bathing: [
    {
      course: "AROMATIC FLOWER BATH",
      cost: "30 min (850THB)",
      detail: "Immerse in a rejuvenating tropical-style bath filled with a multicolor palette of fragrant fresh flowers. The mind is soothed as the body is relaxed and the skin is smoothed.",
    },
    {
      course: "THAI HERBAL BATH",
      cost: "30 min (850THB)",
      detail: "Dip into a revitalizing bath combining the healing power of aromatic Thai herbs with the detoxifying effects of sea salt. As the minerals draw out toxins and impurities from the body, the mind is elevated to a higher plane. ",
    },
    {
      course: "MILK BATH",
      cost: "30 min (850THB)",
      detail: "Immerse in this divine creamy milk bath. The closely-guarded formula provides long-lasting nourishment and hydration for your skin. The wonderfully soothing effect is intensified by fresh rose petals strewn on the surface.",
    },
  ]
}
