import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectAppState} from '../../../state/app.state';

@Component({
  selector: 'app-bodyspamenu',
  templateUrl: './bodyspamenu.component.html',
  styleUrls: ['./bodyspamenu.component.css']
})
export class BodyspamenuComponent implements OnInit {

  showMe = true;
  showMe1 = true;


  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(private store: Store<AppState>) {

  }
  ngOnInit() {
    this.app$.subscribe(state => this.app = state);
    console.log("===> ",this.app.translate.menuBody.coursesMassage[0]);

  }

  All() {
    this.showMe1 = true;
    this.showMe = true;
    // this.hideMe = !this.hideMe;
  }

  toggleTag() {
    this.showMe1 = false;
    this.showMe = true;
    // this.hideMe = !this.hideMe;
  }

  toggleTag1() {
    this.showMe = false;
    this.showMe1 = true;
  }


}
