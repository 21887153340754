import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectAppState} from '../../../state/app.state';

@Component({
  selector: 'app-journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.css']
})

export class JourneyComponent implements OnInit {

  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.app$.subscribe(state => this.app = state.translate.journey);
  }

}
