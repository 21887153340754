<div class="container-fluid background justify-content-center text-center">

  <div class="grid flex">
    <div class="col-1"></div>
    <div class="col-10 mglmain">
      <div class="main titleContainer">
        <div>{{app.branch.common}}</div>
        <div>{{app.branch.s42}}</div>
      </div>
      <div class="container titleShadow">
        <img class="abt1" src="assets/spaimg/new-title23-bg.png" alt="promo05">
      </div>
      <div class="background1">
        <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" preserveContent="true">
          <mat-tab label="SIGNATURE">
            <app-signaturespamenu></app-signaturespamenu>
          </mat-tab>
          <mat-tab label="JOURNEY">
            <app-journeyspamenu></app-journeyspamenu>
          </mat-tab>
          <mat-tab label="BODY">
            <app-bodyspamenu></app-bodyspamenu>
          </mat-tab>
          <mat-tab label="FACIAL">
            <app-facialspamenu></app-facialspamenu>
          </mat-tab>
          <mat-tab label="EXPRESS">
            <app-expressspamenu></app-expressspamenu>
          </mat-tab>
          <mat-tab label="HAIR & NAILS">
            <app-headandnailsspamenu></app-headandnailsspamenu>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
