<div class="w3-row footer show">
  <br>
  <mat-toolbar-row class="sp-btw">
    <div class="mg-left centered butt">
      <a class="text" href="https://web.siameseasset.co.th/exclusive/policy_en.php/">PRIVACY & POLICY</a>
      <div class="space"></div>
      <a class="text" href="https://web.siameseasset.co.th/exclusive/policy_en.php/">TERMS & CONDITION</a>
    </div>
    <div class="mg-right centered">
      <div class="centered">
        <a class="text centered butt" href="#siamesewellness">SIAMESE WELLNESS CO., LTD.</a>
      </div>
      <div class="space"></div>
      <div>
        <button mat-icon-button>
          <a href="https://www.facebook.com/senseceraspa">
            <img class="img1" src="./assets/photo/facebook.png">
          </a>
        </button>
        <button mat-icon-button>
          <a href="https://instagram.com/sense.cera?igshid=YmMyMTA2M2Y=">
            <img class="img2" src="./assets/photo/instagram.png">
          </a>
        </button>
        <button mat-icon-button>
          <a href="https://page.line.me/?accountId=635qpzlu">
            <img class="img3" src="./assets/photo/line.png">
          </a>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="pd-mg-none">
    <div class="mg-left">
      <a href="www.sw.co.th" class="text">www.sw.co.th</a>
    </div>
  </mat-toolbar-row>
  <br>
</div>

<div class="w3-row footer hide butt">
  <div class="w3-row">
    <div class="w3-col s6">
      <br>
      <div class="w3-row text-center">
        <div class="w3-half">
          <a class="text1" href="https://web.siameseasset.co.th/exclusive/policy_en.php">PRIVACY & POLICY</a>
        </div>
        <div class="w3-half">
          <a class="text1" href="https://web.siameseasset.co.th/exclusive/policy_en.php">TERMS & CONDITION</a>
        </div>
      </div>
      <div class="w3-row text-center">
        <a routerLink="/" class="text1">www.sw.co.th</a>
      </div>
      <br>
    </div>
    <div class="w3-col s6">
      <div class="w3-half">
        <br>
        <div class="w3-half text-center">
          <a class="text1" href="#siamesewellness">SIAMESE WELLNESS CO., LTD.</a>
        </div>
        <div>
          <div class="text-center">
            <button mat-icon-button>
              <a href="https://www.facebook.com/senseceraspa">
                <img class="img1" src="./assets/photo/facebook.png">
              </a>
            </button>
            <button mat-icon-button>
              <a href="https://instagram.com/sense.cera?igshid=YmMyMTA2M2Y=">
                <img class="img2" src="./assets/photo/instagram.png">
              </a>
            </button>
            <button mat-icon-button>
              <a href="https://page.line.me/?accountId=635qpzlu">
                <img class="img3" src="./assets/photo/line.png">
              </a>
            </button>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>

<mat-toolbar-row class="gold"></mat-toolbar-row>
