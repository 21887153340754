<div class="w3-row background toolbar">
  <div class="w3-col" style="width:20%">
    <div class="centered none">
      <circle-progress class="steps"
                       [title]="'Step'" [titleFontSize]="18"
                       [subtitle]="'1/3'"
                       [subtitleFontSize]="20"
                       [percent]="33"
                       [radius]="40"
                       [space]="-10"
                       [outerStrokeGradient]="true"
                       [outerStrokeWidth]="10"
                       [outerStrokeColor]="'rgb(236,212,180)'"
                       [outerStrokeGradientStopColor]="'rgba(177,141,118,1)'"
                       [innerStrokeColor]="'rgba(231, 232, 234, 1)'"
                       [innerStrokeWidth]="10"
                       [showUnits]="false"
                       [showBackground]="false"
                       [clockwise]="true"
                       [startFromZero]="false"
      ></circle-progress>
    </div>
  </div>
  <div class="w3-col topmar none" style="width:60%">
    <div class="toolbar centered">
      <h1 class="main">Please Fill in Your Booking Details</h1>
    </div>
    <form [formGroup]="service.form">
      <input type="hidden" formControlName="$key">
      <div class="centered toolbar1">
        <mat-form-field appearance="outline">
          <mat-label>Branch</mat-label>
          <mat-select formControlName="Branch">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let location of locations" [value]="location.name">
              {{location.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="centered toolbar1 centered">
        <mat-form-field appearance="outline">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="myDatePicker" [min]="minDate" [max]="maxDate" formControlName="Date">
          <mat-datepicker-toggle [for]="myDatePicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #myDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="centered toolbar1 centered">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot</mat-label>
          <mat-select formControlName="Time">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let time of times" [value]="time.range">
              {{time.range}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="centered toolbar1">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Login / Register with SIAMESE PRIVILEGE
          </mat-expansion-panel-header>
          <div><br></div>
          <div *ngIf="showMe">
            <div class="row">
              <input class="input" type="input" placeholder="{{'Phone no.' | translate}}" name="pswd" formControlName="phone">
              <button class="submit" type="submit">Send OTP</button>
            </div>
            <div><br></div>
            <div class="row">
              <input class="input" type="input" placeholder="{{'Email' | translate}}" name="pswd1" formControlName="email">
              <button class="submit" type="submit">Send OTP</button>
            </div>
            <div><br></div>
            <div class="row inside">
              <div class="pass">Password</div>
            </div>
            <div><br></div>
            <div><br></div>
            <div class="row">
              <input class="input" type="input" placeholder="{{'Password / OTP' | translate}}" name="pswd2" formControlName="password">
              <button class="submit" type="submit" (click)="toggleTag()">Enter</button>
            </div>
          </div>
          <div *ngIf="hideMe">
            <div class="w3-row centered">
              <div class="w3-half w3-container">
                <img src="/assets/images/SiamesePrivileges/Primary/normal.svg" alt="primary">
              </div>
              <div class="w3-half w3-container posi">
                <div class="name">
                  รวิภาส ร่วมวิบูลย์สุข
                </div>
                <div><br></div>
                <div>
                  {{'mobile' | translate}} : 0858158484
                </div>
                <div><br></div>
                <div><br></div>
                <div>
                  {{'points' | translate}} : <span class="pt">600 pt</span>
                </div>
                   </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
      <div class="centered toolbar2">
        <button [disabled]="service.form.invalid" type="submit" class="button1" routerLink="/customer" (click)="bookFor()" mat-button>{{'CONTINUE' | translate}}</button>
      </div>
    </form>
  </div>
  <div class="w3-col topba centered none" style="width:20%">
    <div>
      <h2 class="text">Booking Details</h2>
      <p class="text1">Next : customer details</p>
    </div>
  </div>
</div>
<div class="row display centered">
  <div>
    <circle-progress class="steps"
                     [title]="'Step'" [titleFontSize]="18"
                     [subtitle]="'1/3'"
                     [subtitleFontSize]="20"
                     [percent]="33"
                     [radius]="40"
                     [space]="-10"
                     [outerStrokeGradient]="true"
                     [outerStrokeWidth]="10"
                     [outerStrokeColor]="'rgb(236,212,180)'"
                     [outerStrokeGradientStopColor]="'rgba(177,141,118,1)'"
                     [innerStrokeColor]="'rgba(231, 232, 234, 1)'"
                     [innerStrokeWidth]="10"
                     [showUnits]="false"
                     [showBackground]="false"
                     [clockwise]="true"
                     [startFromZero]="false"
    ></circle-progress>
  </div>
  <div><br></div>
  <div>
    <h2 class="text">Booking Details</h2>
    <p class="text1">Next : customer details</p>
  </div>
  <div class="toolbar centered">
    <h1 class="main">Please Fill in Your Booking Details</h1>
  </div>
  <form [formGroup]="service.form">
    <input type="hidden" formControlName="$key">
    <div class="centered toolbar1">
      <mat-form-field appearance="outline">
        <mat-label>Branch</mat-label>
        <mat-select formControlName="Branch">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let location of locations" [value]="location.name">
            {{location.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="centered toolbar1 centered">
      <mat-form-field appearance="outline">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="myDatePicker" [min]="minDate" [max]="maxDate" formControlName="Date">
        <mat-datepicker-toggle [for]="myDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #myDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="centered toolbar1 centered">
      <mat-form-field appearance="outline">
        <mat-label>Time Slot</mat-label>
        <mat-select formControlName="Time">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let time of times" [value]="time.range">
            {{time.range}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="centered toolbar1">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Login / Register with SIAMESE PRIVILEGE
        </mat-expansion-panel-header>
        <div><br></div>
        <div *ngIf="showMe">
          <div class="row">
            <input class="input" type="input" placeholder="{{'Phone no.' | translate}}" name="pswd" formControlName="phone">
            <button class="submit" type="submit">Send OTP</button>
          </div>
          <div><br></div>
          <div class="row">
            <input class="input" type="input" placeholder="{{'Email' | translate}}" name="pswd1" formControlName="email">
            <button class="submit" type="submit">Send OTP</button>
          </div>
          <div><br></div>
          <div class="row inside">
            <div class="pass">Password</div>
          </div>
          <div><br></div>
          <div><br></div>
          <div class="row">
            <input class="input" type="input" placeholder="{{'Password / OTP' | translate}}" name="pswd2" formControlName="password">
            <button class="submit" type="submit" (click)="toggleTag()">Enter</button>
          </div>
        </div>
        <div *ngIf="hideMe">
          <div class="w3-row centered">
            <div class="w3-half w3-container">
              <img src="/assets/images/SiamesePrivileges/Primary/normal.svg" alt="primary">
            </div>
            <div class="w3-half w3-container posi">
              <div class="name">
                รวิภาส ร่วมวิบูลย์สุข
              </div>
              <div><br></div>
              <div>
                {{'mobile' | translate}} : 0858158484
              </div>
              <div><br></div>
              <div><br></div>
              <div>
                {{'points' | translate}} : <span class="pt">600 pt</span>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="centered toolbar2">
      <button [disabled]="service.form.invalid" type="submit" class="button1" routerLink="/customer" (click)="bookFor()" mat-button>{{'CONTINUE' | translate}}</button>
    </div>
  </form>
</div>

<!--//toolbar org size//-->
<!--<div class="w3-col topmar none" style="width:60%">-->
<!--  <div class="toolbar centered">-->
<!--    <h1 class="main">Please Fill in Your Booking Details</h1>-->
<!--  </div>-->
<!--  <div class="centered toolbar1">-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Branch</mat-label>-->
<!--      <mat-select [formControl]="locationControl" required [(ngModel)]="bookBranch">-->
<!--        <mat-option>&#45;&#45;</mat-option>-->
<!--        <mat-option *ngFor="let location of locations" [value]="location.name">-->
<!--          {{location.name}}-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--  <div class="centered toolbar1">-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Choose a date</mat-label>-->
<!--      <input matInput [matDatepicker]="myDatePicker" [min]="minDate" [max]="maxDate" [(ngModel)]="bookDate">-->
<!--      <mat-datepicker-toggle [for]="myDatePicker" matSuffix></mat-datepicker-toggle>-->
<!--      <mat-datepicker #myDatePicker></mat-datepicker>-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--  <div class="centered toolbar1">-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Time Slot</mat-label>-->
<!--      <mat-select [formControl]="timeControl" required [(ngModel)]="bookTime">-->
<!--        <mat-option>&#45;&#45;</mat-option>-->
<!--        <mat-option *ngFor="let time of times" [value]="time.range">-->
<!--          {{time.range}}-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--  <div class="centered toolbar1">-->
<!--    <mat-expansion-panel>-->
<!--      <mat-expansion-panel-header>-->
<!--        Login / Register with SIAMESE PRIVILEGE-->
<!--      </mat-expansion-panel-header>-->
<!--      <div><br></div>-->
<!--      <div *ngIf="showMe">-->
<!--        <div class="row">-->
<!--          <input class="input" type="input" id="pwd" placeholder="{{'Phone no.' | translate}}" name="pswd"-->
<!--                 [(ngModel)]="bookPhone">-->
<!--          <button class="submit" type="submit" (click)="sendOTP()">Send OTP</button>-->
<!--        </div>-->
<!--        <div><br></div>-->
<!--        <div class="row">-->
<!--          <input class="input" type="input" id="pwd1" placeholder="{{'Email' | translate}}" name="pswd1"-->
<!--                 [(ngModel)]="bookEmail">-->
<!--          <button class="submit" type="submit" (click)="sendOTP()">Send OTP</button>-->
<!--        </div>-->
<!--        <div><br></div>-->
<!--        <div class="row inside">-->
<!--          <div class="pass">Password</div>-->
<!--        </div>-->
<!--        <div><br></div>-->
<!--        <div><br></div>-->
<!--        <div class="row">-->
<!--          <input class="input" type="input" id="pwd2" placeholder="{{'Password / OTP' | translate}}" name="pswd2"-->
<!--                 [(ngModel)]="bookOTP">-->
<!--          <button class="submit" type="submit" (click)="toggleTag()">Enter</button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div *ngIf="hideMe">-->
<!--        <div class="w3-row centered">-->
<!--          <div class="w3-half w3-container">-->
<!--            <img src="/assets/images/SiamesePrivileges/Primary/normal.svg" alt="primary">-->
<!--          </div>-->
<!--          <div class="w3-half w3-container posi">-->
<!--            <div class="name">-->
<!--              รวิภาส ร่วมวิบูลย์สุข-->
<!--            </div>-->
<!--            <div><br></div>-->
<!--            <div>-->
<!--              {{'mobile' | translate}} : 0858158484-->
<!--            </div>-->
<!--            <div><br></div>-->
<!--            <div><br></div>-->
<!--            <div>-->
<!--              {{'points' | translate}} : <span class="pt">600 pt</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </mat-expansion-panel>-->
<!--  </div>-->
<!--  <div class="centered toolbar2">-->
<!--    <button class="button1" routerLink="/customer" (click)="bookFor()" mat-button>{{'CONTINUE' | translate}}</button>-->
<!--  </div>-->
<!--</div>-->
