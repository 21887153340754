import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing/app-routing.module';

import {AppComponent} from './app.component';
import {MaterialModule} from './material/material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AboutpageComponent} from './aboutpage/aboutpage.component';
import {HeaderComponent} from './header/header.component';
import {BannerComponent} from './banner/banner.component';
import {PromotionsComponent} from './promotions/promotions.component';
import {SpecialOfferComponent} from './special-offer/special-offer.component';
import {FooterComponent} from './footer/footer.component';
import {NgCircleProgressModule} from 'ng-circle-progress';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HomeComponent} from './home/home.component';
import {PromosComponent} from './promos/promos.component';
import {LocationsComponent} from './branches/locations.component';
import {ContactusComponent} from './contactus/contactus.component';
import {ForminputComponent} from './forminput/forminput.component';
import {SpaProgramsComponent} from './spa-programs/spa-programs.component';
import {CustomerdetailsComponent} from './customerdetails/customerdetails.component';

import {CarouselModule} from 'ngx-owl-carousel-o';
import {UserprogramsComponent} from './userprograms/userprograms.component';
import {Customername1Component} from './customername1/customername1.component';
import {FormdataService} from './shared/formdata.service';
import {MapComponent} from './map/map.component';
import {Map1Component} from './map1/map1.component';
import {Map2Component} from './map2/map2.component';
import {Spa42programComponent} from './SpaMenu/S42Program/spa42program.component';
import {SignaturespamenuComponent} from './SpaMenu/Category/Signature/signaturespamenu.component';
import {JourneyspamenuComponent} from './SpaMenu/Category/Journey/journeyspamenu.component';
import {BodyspamenuComponent} from './SpaMenu/Category/Body/bodyspamenu.component';
import {FacialspamenuComponent} from './SpaMenu/Category/Facial/facialspamenu.component';
import {ExpressspamenuComponent} from './SpaMenu/Category/Express/expressspamenu.component';
import {HeadandnailsspamenuComponent} from './SpaMenu/Category/HairNail/headandnailsspamenu.component';
import {Spa48programComponent} from './SpaMenu/S48Program/spa48program.component';
import {Journeyspa48andqueenmenuComponent} from './SpaMenu/Category/JourneyQueenS48/journeyspa48andqueenmenu.component';
import {SpaqueenprogramComponent} from './SpaMenu/QueenProgram/spaqueenprogram.component';
import {StoreModule} from '@ngrx/store';
import {environment} from '../environments/environment.prod';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {translateReducer} from './state/translate/translate.reducer';
import { SignatureComponent } from './SpaMenu/Category2/signature/signature.component';
import { JourneyComponent } from './SpaMenu/Category2/journey/journey.component';
import { BodyComponent } from './SpaMenu/Category2/body/body.component';
import { FacialComponent } from './SpaMenu/Category2/facial/facial.component';
import { ExpressComponent } from './SpaMenu/Category2/express/express.component';
import { HairAndNailsComponent } from './SpaMenu/Category2/hair-and-nails/hair-and-nails.component';
import { HeaderDesktopComponent } from './header/header-desktop/header-desktop.component';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { BranchS42Component } from './branches/branch-s42/branch-s42.component';
import { BranchS48Component } from './branches/branch-s48/branch-s48.component';
import { BranchQueenComponent } from './branches/branch-queen/branch-queen.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import {LayoutDefaultComponent} from './layouts/layout-default/layout-default.component';
import {LayoutLandingComponent} from './layouts/layout-landing/layout-landing.component';
import {LayoutSwitchingComponent} from './layouts/layout-switching/layout-switching.component';
import {configReducer} from './state/config/config.reducer';
import { PromolandingComponent } from './promolanding/promolanding.component';
import { QueenpromoComponent } from './queenpromo/queenpromo.component';
import { QpromotionsComponent } from './qpromotions/qpromotions.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AboutpageComponent,
    HeaderComponent,
    BannerComponent,
    PromotionsComponent,
    SpecialOfferComponent,
    FooterComponent,
    HomeComponent,
    PromosComponent,
    LocationsComponent,
    ContactusComponent,
    ForminputComponent,
    SpaProgramsComponent,
    CustomerdetailsComponent,
    UserprogramsComponent,
    Customername1Component,
    MapComponent,
    Map1Component,
    Map2Component,
    Spa42programComponent,
    SignaturespamenuComponent,
    JourneyspamenuComponent,
    BodyspamenuComponent,
    FacialspamenuComponent,
    ExpressspamenuComponent,
    HeadandnailsspamenuComponent,
    Spa48programComponent,
    Journeyspa48andqueenmenuComponent,
    SpaqueenprogramComponent,
    SignatureComponent,
    JourneyComponent,
    BodyComponent,
    FacialComponent,
    ExpressComponent,
    HairAndNailsComponent,
    HeaderDesktopComponent,
    HeaderMobileComponent,
    BranchS42Component,
    BranchS48Component,
    BranchQueenComponent,
    LayoutDefaultComponent,
    LayoutLandingComponent,
    LayoutSwitchingComponent,
    LandingpageComponent,
    PromolandingComponent,
    QueenpromoComponent,
    QpromotionsComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgCircleProgressModule.forRoot({}),

    StoreModule.forRoot({
      translate: translateReducer,
      config: configReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
  ],
  providers: [
    HttpClient,
    FormdataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

