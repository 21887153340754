<div class="container">

  <div class="course">{{app.translate.menuBody.title.toUpperCase()}}</div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[0].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[0].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[0].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[1].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[1].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[1].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[2].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[2].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[2].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[3].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[3].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[3].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[4].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[4].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[4].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[5].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[5].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[5].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[6].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[6].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[6].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[7].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[7].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[7].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesMassage[8].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesMassage[8].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesMassage[8].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="flexImage">
    <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu7.png" alt="Sense Cera">
    <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu8.png" alt="Sense Cera">
  </div>

</div>

<div class="container">

  <div class="course">{{app.translate.menuBody.title2.toUpperCase()}}</div>

  <img class="separator"
       src="../../../../assets/component/icons/lineflo.png" alt="separator">

  <div class="itemContainer">

    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesTreatments[0].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesTreatments[0].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesTreatments[0].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">

  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesTreatments[1].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesTreatments[1].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesTreatments[1].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">

  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesTreatments[2].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesTreatments[2].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesTreatments[2].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">

    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesTreatments[3].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesTreatments[3].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesTreatments[3].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <div class="flexTitle">
      <div class="treatmentName">{{app.translate.menuBody.coursesTreatments[4].course}}</div>
      <div class="treatmentPrice">{{app.translate.menuBody.coursesTreatments[4].cost}}</div>
    </div>

    <p class="description">
      {{app.translate.menuBody.coursesTreatments[4].detail}}
    </p>

    <img class="separator"
         src="../../../../assets/component/icons/lineflo.png" alt="separator">
  </div>

  <div class="itemContainer">
    <img class="singleImage" src="../../../../assets/component/spamenu/spamenu9.png" alt="Sense Cera">
  </div>

  <div class="flexImage">
    <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu10.png" alt="Sense Cera">
    <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu11.png" alt="Sense Cera">
  </div>
</div>
