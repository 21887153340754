import { translateTH } from '../../../state/translate/translate.actions';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, selectAppState } from '../../../state/app.state';

@Component({
  selector: 'app-headandnailsspamenu',
  templateUrl: './headandnailsspamenu.component.html',
  styleUrls: ['./headandnailsspamenu.component.css']
})
export class HeadandnailsspamenuComponent implements OnInit {

  showMe = true;
  showMe1 = true;



  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(private store: Store<AppState>) {

  }
  ngOnInit() {
    this.app$.subscribe(state => this.app = state.translate.hairAndNails);
    // console.log("===> ",  JSON.stringify(this.app));

  }
  All() {
    this.showMe1 = true;
    this.showMe = true;
    // this.hideMe = !this.hideMe;
  }
}
