export const facialTh = {
  title: "Facial Treatments",
  courses: [
    {
      course: "PHYTOMER CLASSIC AL SOOTHING FACIAL TREATMENT",
      cost: "60 นาที (2,520 บาท)",
      detail: "ทรีตเมนต์สำหรับการปรนเปรอผิวหน้าชั้นเลิศที่ช่วยฟื้นบำรุงผิวอย่างอ่อนโยน เติมความชุ่มชื่นให้กับผิว และเสริมเกราะป้องกันเพื่อให้ผิวแข็งแรงขึ้น ให้ผลลัพธ์เป็นผิวที่ผ่อนคลายและสวยเปล่งปลั่ง",
    },
    {
      course: "PHYTOMER Moisturizing Facial Treatment",
      cost: "60 นาที (2,160 บาท)",
      detail: "เติมความอิ่มน้ำให้กับผิวที่ขาดแคลนความชุ่มชื่นด้วยสาหร่ายทะเลออร์แกนิก บำรุงผิวด้วยสกินแคร์ที่มอบพลังด้านความชุ่มชื้น และในขณะเดียวกันก็ช่วยผ่อนคลายผิวขั้นสุด ด้วยส่วนผสมจากธรรมชาติที่เป็นออร์แกนิกทั้งหมด จะปลุกเร้าทุกสัมผัส และเทคนิคการนวดเพื่อช่วยผ่อนคลาย จะเสกผิวที่เปล่งปลั่ง อวบอิ่ม และสดชื่นจากการได้พักผ่อนอย่างเต็มที่",
    },
    {
      course: "PHYTOMER Anti Wrinkle Facial Treatment",
      cost: "60 นาที (2,520 บาท)",
      detail: "ทรีตเมนต์ที่ช่วยยกกระชับและจัดการกับริ้วรอยภายใน 3 ขั้นตอน ทรีตเมนต์เพื่อการต่อต้านริ้วรอยประสิทธิภาพสูงสูตรนี้ จะช่วยปรับผิวให้เรียบเนียน เติมเต็มส่วนที่เป็นร่องหรือริ้วรอย และปรับโครงสร้างของใบหน้าเพื่อความอ่อนเยาว์ สัมผัสผลลัพธ์ได้ว่าริ้วรอยจางลงและผิวของท่านจะกระชับขึ้นและเปล่งปลั่งได้มากกว่าเดิม",
    },
    {
      course: "PHYTOMER White Lumination Facial Treatment ",
      cost: "60 นาที (2,160 บาท)",
      detail: "ทรีตเมนต์ที่จะเข้าจัดการกับปัญหาริ้วรอยและช่วยปรับสีผิวให้สว่างขึ้น จุดหมองคล้ำ ริ้วรอย และปัญหาผิวอื่น ๆ จะลดเลือนไป คงเหลือเพียงผิวที่เปล่งประกาย เรียบเนียน และสม่ำเสมอ",
    },
    {
      course: "EYES TREATMENT",
      cost: "30 นาที (1,500 บาท)",
      detail: "ทรีตเมนต์ผิวรอบดวงตาที่เปี่ยมไปด้วยประสิทธิภาพขั้นสูง เพื่อผิวรอบดวงตาที่อ่อนเยาว์และเปล่งประกาย ทำงานร่วมกับทรีตเมนต์ผิวหน้าที่ท่านเลือก โปรแกรมนี้จะผสานความสามารถในการฟื้นบำรุงที่ล้ำลึกกับศาสตร์ของการผ่อนคลาย หลังทรีตเมนต์เพียงแค่หนึ่งครั้ง ท่านจะสัมผัสได้ถึงความเรียบเนียนและสดใสของผิวรอบดวงตาทันที",
    },
    {
      course: "PHYTOMER Classical Homme Facial for Men",
      cost: "60 นาที (2,160 บาท)",
      detail: "จุดจบของปัญหาผิวกับทรีตเมนต์เพื่อผิวของท่านชายที่สมบูรณ์แบบ สุขภาพดีกว่าเดิม กระจ่างใสมากกว่าเดิม และคืนความสดชื่นให้กับผิว",
    },

  ],
}
