<mat-toolbar class="toolbar animate__animated animate__fadeIn">
  <div class="grid flex">
    <div class="col-4"><br></div>
    <div class="col-4 centered">
      <img class="imglogo" src="assets/spaimg/Sense-cera-logo-color1.png" alt="logo">
    </div>
    <div class="col-4">
      <div class="center1 marginr">
        <div class="mg "></div>
        <div class="btom">
          <span class="langua mgrr actived" (click)="translate()">TH</span>
          <span class="mgrr">|</span>
          <span class="langua mgrr actived" (click)="translate()">EN</span>
        </div>
      </div>
    </div>
  </div>

  <app-header-desktop></app-header-desktop>
  <app-header-mobile></app-header-mobile>

  <mat-toolbar-row class="gold"></mat-toolbar-row>
</mat-toolbar>
