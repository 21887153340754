<div class="w3-row background promo">
  <!--  <div class="show"><br></div>-->
  <div class="space"></div>

  <div class="w3-row">
    <div class="w3-col w3-container">
      <div class="title">{{app.title}}</div>
      <div class="container">
        <img class="abt1" src={{img[0]}} alt="promo05">
      </div>
    </div>
  </div>

  <section class="padder">
    <!-- <owl-carousel-o [options]="proOptions">
      <ng-template carouselSlide>
        <img fetchpriority="high" class="mySlides imgh"
             src={{img[1]}} style="width:100%">
      </ng-template>
      <ng-template carouselSlide>
        <img fetchpriority="high" class="mySlides imgh"
             src={{img[2]}} style="width:100%">
      </ng-template>
      <ng-template carouselSlide>
        <img fetchpriority="high" class="mySlides imgh"
             src={{img[3]}} style="width:100%">
      </ng-template>
           <ng-template carouselSlide>
           <img fetchpriority="high" class="mySlides imgh" src="/assets/spaimg/Aw-Promotion-03.png" style="width:100%">
           </ng-template>
          <ng-template carouselSlide>
          <img fetchpriority="high" class="mySlides imgh" src="/assets/spaimg/Aw-Promotion-02.png" style="width:100%">
          </ng-template>
          <ng-template carouselSlide>
          <img fetchpriority="high" class="mySlides imgh" src="/assets/spaimg/Aw-Promotion-01.png" style="width:100%">
          </ng-template>
    </owl-carousel-o> -->
    <!-- <img class="imgh" src="/assets/component/promotion-img/promoS42.png" alt=""> -->
    <img class="imgh" src="/assets/component/promotionApril/S42/horizontal/aprilhorizontal.jpg" alt="">
  </section>

  <section class="padder">
    <img class="imgh" src="/assets/component/promotionApril/S42/horizontal/aprilhorizontal2.jpg" alt="">
  </section>

  <div class="container-fluid centered">
    <img class="abt" src="/assets/spaimg/new-title23-bg.png" alt="promo06">
  </div>
</div>

