import {Component, Input, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {SpaProgramsComponent} from '../spa-programs/spa-programs.component';
import { UntypedFormControl, FormBuilder, Validators } from '@angular/forms';
import {FormdataService} from '../shared/formdata.service';

interface Location {
  id: number;
  name: string;
}

interface Time {
  range: string;
}

@Component({
  selector: 'app-forminput',
  templateUrl: './forminput.component.html',
  styleUrls: ['./forminput.component.css']
})

export class ForminputComponent {

  constructor(public service: FormdataService) {
  }

  minDate = new Date();
  maxDate = new Date(2023, 1, 1);

  bookDate: Date;

  locationControl = new UntypedFormControl('');
  selectFormControl = new UntypedFormControl('');
  locations: Location[] = [
    { id: 1 , name: 'Location1'},
    { id: 2, name: 'Location2'},
    { id: 3 , name: 'Location3'},
  ];

  timeControl = new UntypedFormControl('');
  selectTimeControl = new UntypedFormControl('');
  times: Time[] = [
    {range: '10.00'},
    {range: '11.00'},
    {range: '12.00'},
    {range: '13.00'},
    {range: '14.00'},
    {range: '15.00'},
    {range: '16.00'},
    {range: '17.00'},
    {range: '18.00'},
  ];

  bookFor() {
    console.log(this.service);
  }

  showMe:boolean = true;
  hideMe:boolean = false;

  toggleTag() {
    this.showMe = !this.showMe;
    this.hideMe = !this.hideMe;
  }
}

