<mat-toolbar-row class="toolbar3 centered appear">
  <div>
    <button class="logo" mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
      <mat-icon>menu</mat-icon>
    </button>

    <mat-menu #menu="matMenu" fxShow="true" fxHide.gt-xs>
      <button mat-menu-item routerLink="/">
        <mat-icon class="icon">home</mat-icon>
        <span class="headerFontMobile" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">{{app.translate.header.home}}</span>
      </button>
      <button mat-menu-item routerLink="/branches">
        <mat-icon class="icon">place</mat-icon>
        <span class="headerFontMobile" routerLinkActive="active">{{app.translate.header.branches}}</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="menubranch">
        <mat-icon class="icon">menu_book</mat-icon>
        <span class="headerFontMobile">{{app.translate.header.menus}}</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="promobranch">
        <mat-icon class="icon">discount</mat-icon>
        <span class="headerFontMobile" routerLinkActive="active">{{app.translate.header.promotions}}</span>
      </button>
      <button mat-menu-item routerLink="/about">
        <mat-icon class="icon">info</mat-icon>
        <span class="headerFontMobile" routerLinkActive="active">{{app.translate.header.about}}</span>
      </button>
      <button mat-menu-item routerLink="/contactus">
        <mat-icon class="icon">contact_support</mat-icon>
        <span class="headerFontMobile" routerLinkActive="active">{{app.translate.header.contact}}</span>
      </button>
    </mat-menu>
    <mat-menu #menubranch="matMenu">
      <button mat-menu-item routerLink="/spa42menu">
        <mat-icon class="icon">spa</mat-icon>
        <span>Sukhumvit 42 Menu</span>
      </button>
      <button mat-menu-item routerLink="/spaqueenmenu">
        <mat-icon class="icon">spa</mat-icon>
        <span>Queen Menu</span>
      </button>
      <button mat-menu-item routerLink="/spa48menu">
        <mat-icon class="icon">spa</mat-icon>
        <span>Sukhumvit 48 Menu</span>
      </button>
    </mat-menu>
    <mat-menu #promobranch="matMenu">
      <button mat-menu-item routerLink="/s42promotion">
        <mat-icon class="icon">spa</mat-icon>
        <span>Sukhumvit 42 Promotions</span>
      </button>
      <button mat-menu-item routerLink="/qpromotion">
        <mat-icon class="icon">spa</mat-icon>
        <span>Queen Promotions</span>
      </button>
    </mat-menu>
  </div>

  <div class="cenn">
    <span class="langua mgrr actived" (click)="translate()">TH</span>
    <span class="mgrr wh">|</span>
    <span class="langua mgrr actived" (click)="translate()">EN</span>
  </div>
</mat-toolbar-row>
