export const hairAndNailsTh = {
  title: "Hair Treatment - Manicures Pedicures",
  courses: [
    {
      course: "NAIL EXPRESS",
      time: "30 นาที",
      price: "490 บาท"
    },
    {
      course: "GENTLEMAN'S MANICURE",
      time: "30 นาที",
      price: "590 บาท"
    },
    {
      course: "GENTLEMAN'S PEDICURE",
      time: "30 นาที",
      price: "590 บาท"
    },
    {
      course: "CLASSIC MANICURE",
      time: "45 นาที",
      price: "890 บาท"
    },
    {
      course: "CLASSIC PEDICURE",
      time: "45 นาที",
      price: "890 บาท"
    },
    {
      course: "DELUXE PEDICURE",
      time: "60 นาที",
      price: "1,290 บาท"
    },
    {
      course: "DELUXE MANICURE",
      time: "60 นาที",
      price: "1,290 บาท"
    },
    {
      course: "HAIR TREATMENT AND BLOW DRY",
      time: "60 นาที",
      price: "1,290 บาท"
    },

  ]
}
