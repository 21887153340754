export const signatureTh = {
  title: "Signature Programs",
  courses: [
    {
      course: "PINDA SWEDANA MASSAGE",
      cost: "90/120 นาที (2,160/2,640 บาท)",
      detail: "ศาสตร์การนวดอายุรเวท การนวดแบบดั้งเดิมที่เป็นต้นกำเนิดของการนวดทุกรูปแบบในโลก ช่วยรักษาอาการและฟื้นฟูร่างกายได้อย่างดีเยี่ยม ในขั้นตอนของการนวดแบบ Pinda Swedana จะใช้ลูกประคบที่อบด้วยสมุนไพร น้ำนม และข้าว นวดประคบไปตามจุดต่าง ๆ ทั่วร่างกาย อุณหภูมิที่อุ่นกำลังดีจะช่วยให้น้ำนมและสมุนไพรสามารถซึมลึกลงไปถึงชั้นกล้ามเนื้อ ช่วยกระตุ้นการไหลเวียนของระบบเลือด ช่วยผ่อนคลายกล้ามเนื้อที่ตึงตัว เสริมสร้างความแข็งแรงให้กับระบบประสาท และคืนความกระปรี้กระเปร่าให้กับทุกส่วนของร่างกาย",
    },
    {
      course: "SIAM BLENDED MASSAGE",
      cost: "90/120 นาที (2,160/2,640 บาท)",
      detail: "สัมผัสผลลัพธ์แห่งการปลดล็อกและความเป็นอิสระด้วยเทคนิคที่ผสมผสาน 4 รูปแบบของการนวดเข้าไว้ด้วยกัน ทั้งนวดแผนไทย การนวดสวีดิช การนวดชิอัตซึจากญี่ปุ่น และโลมีโลมีจากฮาวาย ผนึกกำลังการนวดแบบตะวันออกที่เน้นการออกแรงกดเข้ากับศาสตร์การนวดแบบตะวันตกที่เน้นให้ความผ่อนคลาย นำไปสู่ผลลัพธ์ทางการรักษาที่ท่านสามารถสัมผัสได้ในทันที สิ่งที่คงเหลือไว้หลังจากทรีตเมนต์คือความรู้สึกอ่อนเยาว์ขึ้น เปี่ยมไปด้วยความสุขมากยิ่งขึ้น และพร้อมสำหรับกิจกรรมต่อไปของวัน",
    },
    {
      course: "AYURVEDIC ABHYANGA MASSAGE",
      cost: "90/120 นาที (2,160/2,640 บาท)",
      detail: "การนวดเพื่อผ่อนคลายด้วยการใช้น้ำมันอุ่น ช่วยขับของเสียและคืนความชุ่มชื่นให้กับผิวไปพร้อมกันด้วยการใช้เทคนิคของการนวดแบบดั้งเดิมผสานกับการกด Marma Points จุดสำคัญในบริเวณต่าง ๆ ของร่างกาย หรือจุดตัดบริเวณที่กล้ามเนื้อหลายมัดประสานกัน เพื่อการฟื้นฟูรักษาที่ล้ำลึก เมื่อน้ำมันอุ่นร้อนทำงานประสานกับจุดเหล่านี้แล้ว ร่างกายจะถูกปรับสมดุล และความรู้สึกมีชีวิตชีวาจะหวนกลับมาอีกครั้ง",
    },
  ],
};
