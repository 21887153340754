<div class="container">
  <div>
    <div class="course">{{app.translate.facial.title.toUpperCase()}}</div>

    <div class="itemContainer">
      <div class="flex">
        <div class="treatmentName">{{app.translate.facial.courses[0].course}}</div>
        <div class="treatmentPrice">{{app.translate.facial.courses[0].cost}}</div>
      </div>

      <p class="description">
        {{app.translate.facial.courses[0].detail}}
      </p>
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>

    <div class="itemContainer">
      <div class="flex">
        <div class="treatmentName">{{app.translate.facial.courses[1].course}}</div>
        <div class="treatmentPrice">{{app.translate.facial.courses[1].cost}}</div>
      </div>

      <p class="description">
        {{app.translate.facial.courses[1].detail}}
      </p>

      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">

    </div>

    <div class="itemContainer">

      <div class="flex">
        <div class="treatmentName">{{app.translate.facial.courses[2].course}}</div>
        <div class="treatmentPrice">{{app.translate.facial.courses[2].cost}}</div>
      </div>

      <p class="description">
        {{app.translate.facial.courses[2].detail}}
      </p>

      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>

    <div class="itemContainer">
      <div class="flex">
        <div class="treatmentName">{{app.translate.facial.courses[3].course}}</div>
        <div class="treatmentPrice">{{app.translate.facial.courses[3].cost}}</div>
      </div>

      <p class="description">
        {{app.translate.facial.courses[3].detail}}
      </p>

      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>

    <div class="itemContainer">

      <div class="flex">
        <div class="treatmentName">{{app.translate.facial.courses[4].course}}</div>
        <div class="treatmentPrice">{{app.translate.facial.courses[4].cost}}</div>
      </div>

      <p class="description">
        {{app.translate.facial.courses[4].detail}}
      </p>

      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">

    </div>

    <div class="itemContainer">
      <div class="flex">
        <div class="treatmentName">{{app.translate.facial.courses[5].course}}</div>
        <div class="treatmentPrice">{{app.translate.facial.courses[5].cost}}</div>
      </div>

      <p class="description">
        {{app.translate.facial.courses[5].detail}}
      </p>

      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>

    <div>
      <img class="singleImage" src="../../../../assets/component/spamenu/spamenu12.png" alt="linedec3">
    </div>

  </div>
</div>
