import {Component, Input, OnInit} from '@angular/core';
import {MapComponent} from '../map/map.component';
import {Map1Component} from '../map1/map1.component';
import {Map2Component} from '../map2/map2.component';
import {MatDialog} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {AppState, selectAppState} from '../state/app.state';
import {translateEN, translateTH} from '../state/translate/translate.actions';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  @Input() deviceXs: boolean;
  showFiller = false;

  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  Queens: Array<any> = [
    {
      name: 'Wyndham Bangkok Queen Convention Center',
      image: '../assets/spaimg/Queen_Lobby.png',
      address: '388 Soi Phai Singto, Khlong Toei Subdistrict, Khlong Toei District, Bangkok 10110',
      address1: '388 Soi Phai Singto, Khlong Toei Subdistrict,',
      address2: 'Khlong Toei District, Bangkok 10110',
      phone: 'Tel. +66 (0)2 331 2442',
      website: 'website: www.sw.com',
      email: 'e-mail: resvqueen@sw.co.th',
      line: 'Line ID: SiameseWellness',
      map: '../assets/spaimg/Wyndham-queen-map.png',
    },
  ];

  S42s: Array<any> = [
    {
      name: 'Wyndham Garden Bangkok Sukhumvit 42',
      image: '../assets/spaimg/Spa 42_Lobby.png',
      address: '19 Soi Sukhumvit 42, Phra Khanong Subdistrict, Khlong Toei District, Bangkok 10110',
      address1: '19 Soi Sukhumvit 42, Phra Khanong Subdistrict,',
      address2: 'Khlong Toei District, Bangkok 10110',
      phone: 'Tel. +66 (0)2 331 1022',
      website: 'website: www.sw.com',
      email: 'e-mail: resv42@sw.co.th',
      line: 'Line ID: SiameseWellness',
      map: '../assets/spaimg/Wyndham-42-map.png',
    }
  ];

  S48s: Array<any> = [
    {
      name: 'Wyndham Garden Bangkok Sukhumvit 48',
      image: '../assets/component/spa48/Spa 48_Lobby 2.png',
      address: '1448 Soi Sukhumvit 48, Phra Khanong Subdistrict, Khlong Toei District, Bangkok 10110',
      address1: '1448 Soi Sukhumvit 48, Phra Khanong Subdistrict,',
      address2: 'Khlong Toei District, Bangkok 10110',
      phone: 'Tel. +66 (0)2 331 1066',
      website: 'website: www.sw.com',
      email: 'e-mail: resv48@sw.co.th',
      line: 'Line ID: SiameseWellness',
      map: '../assets/spaimg/Wyndham-48-map.png',
    }
  ];

  lang;

  constructor(public dialog: MatDialog,private store: Store<AppState>) {
  }

  openDialog1(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(MapComponent, {
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openDialog2(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(Map1Component, {
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openDialog3(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(Map2Component, {
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  ngOnInit() {
    this.lang = localStorage.getItem('lang') || 'en';
    this.app$.subscribe(state => this.app = state);
  }

}
