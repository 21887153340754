import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, selectAppState } from '../../../state/app.state';

@Component({
  selector: 'app-facialspamenu',
  templateUrl: './facialspamenu.component.html',
  styleUrls: ['./facialspamenu.component.css']
})
export class FacialspamenuComponent implements OnInit {

  showMe = true;
  showMe1 = true;



  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(private store: Store<AppState>) {

  }
  ngOnInit() {
    this.app$.subscribe(state => this.app = state);
    console.log("===> ", this.app);

  }
  All() {
    this.showMe1 = true;
    this.showMe = true;
    // this.hideMe = !this.hideMe;
  }
}
