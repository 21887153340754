export const facialEn = {
  title: "Facial Treatments",
  courses: [
    {
      course: "PHYTOMER CLASSIC AL SOOTHING FACIAL TREATMENT",
      cost: "60 min (2,520THB)",
      detail: "An exceptionally nurturing facial treatment that softens and hydrates sensitive skin and strengthens defenses with results at once soothing and aesthetic.",
    },
    {
      course: "PHYTOMER Moisturizing Facial Treatment",
      cost: "60 min (2,160THB)",
      detail: "A plumping thirst-relief facial with organic algae, a skincare essential that pairs moisturizing performance with intense relaxation. Organic ingredients, sense-awakening textures and intuitive massage leave your skin soothed and glowing, plumped and rested.",
    },
    {
      course: "PHYTOMER Anti Wrinkle Facial Treatment",
      cost: "60 min (2,520THB)",
      detail: "A wrinkle correcting and firming treatment in three steps. This high-performance anti-aging treatment smooths skin, inflates away wrinkles and restructures facial contours. Wrinkles are less visible and the skin is firmer and more radiant, leaving you looking youthful.",
    },
    {
      course: "PHYTOMER White Lumination Facial Treatment ",
      cost: "60 min (2,160THB)",
      detail: "An ultra-comprehensive anti-aging treatment that brightens skin tone. Dark spots, wrinkles and other complexion flaws are dialed way down, leaving the skin radiant, smoothed and even.",
    },
    {
      course: "EYES TREATMENT",
      cost: "30 min (1,500THB)",
      detail: "A complete and effective radiance and smoothing treatment for beautiful eyes that sparkle with youth. Incorporated into your choice of facial treatment, this targeted eye contour treatment combines sensory soothing products with revitalizing massage. In one go, the eye area is visibly smoothed and revitalized.",
    },
    {
      course: "PHYTOMER Classical Homme Facial for Men",
      cost: "60 min (2,160THB)",
      detail: "Banish blemishes with this effective flaw-fading men’s facial treatment. Healthier, clearer skin, a fresher complexion and accentuated handsomeness unfold.",
    },

  ],
}
