export const bodyEn = {
  title: "BODY MASSAGE",
  title2: "BODY Treatments",
  coursesMassage: [
    {
      course: "JET-LAG RECOVERY MASSAGE",
      cost: "60/90 min (1,440/1,920 THB)",
      detail:
        "Re-balance your energies with this therapeutic massage tailored to relieving travel stress from long-haul flights. The deeply relaxing technique resets your body clock and revives your energy level overall.",
    },
    {
      course: "AROMA THERAPY MASSAGE",
      cost: "60/90 min (1,440/1,920 THB)",
      detail:
        "An intensely sensory and relaxing massage applying essential oils to relax the body and awaken the mind. Smooth, soothing movements create a deep-rooted sense of peacefulness and serenity.",
    },
    {
      course: "SWEDISH THAI FUSION MASSAGE",
      cost: "60/90 min (1,800/2,400 THB)",
      detail:
        "A deep massage at times bordering on a healing kind of pain but in an eminently elevating way. This treatment will leave you singing the praises of its therapeutic effects. You will especially relish how dexterous pressuring and soothing stretches melts away muscular tension and knots. Sweet moments of trial unfold blissful revitalization.",
    },
    {
      course: "HIMALAYAN SALT STONE MASSAGE",
      cost: "90/120 min (2,160/2,640 THB)",
      detail:
        "In the dexterous hands of your therapist, warmed Himalayan Salt Stones massage away tension from your limbs to your muscles and sinews. As the salt crystals are absorbed by the skin, a sharpened sense of wellbeing emerges, firmly rooted in enhanced circulation and natural balancing of the nervous system. You’ll sleep better too.",
    },
    {
      course: "DEEP TISSUE MASSAGE",
      cost: "60/90 min (2,160/3,000 THB)",
      detail:
        "Encompassing a set of powerful intuitive hand movements, this invigorating treatment combines classical Swedish-style massage with compression and trigger point techniques. Soothing and stimulating muscles in your thighs, shoulders, arms and back fades all soreness away.",
    },
    {
      course: "TRADITIONAL THAI MASSAGE",
      cost: "60/90 min (1,200/1,560 THB)",
      detail:
        "Matchless Thai traditional body massage needs no oils or lotions. Rather than rubbing on muscles, the body is compressed, pulled, stretched and rocked. Such remarkable techniques accentuate flexibility, relieve muscular tension and leave you walking on air.",
    },
    {
      course: "THAI HERBAL COMPRESSION ",
      cost: "90/120 min (1,800/2,400 THB)",
      detail:
        "A Thai-inspired ritual combining deep tissue massage techniques with a hot herbal compress, pleasantly releasing tension in the shoulders and back muscles. Compresses blend coarse medicinal herbs that massage movements penetrate deeply into tissues. The full body massage soothes muscle tension and stiffness, capping a thoroughly elevating experience.",
    },
    {
      course: "ORIENTAL FOOT MASSAGE",
      cost: "60/90 min (1,200/1,560 THB)",
      detail:
        "A dynamic treatment mastering the principles of reflexology. Linking the reflex zones on the feet to organs and systems in the body, tension is relieved and wellbeing revived. ",
    },
    {
      course: "ANTI-STRESS HEAD/BACK/SHOULDER MASSAGE",
      cost: "60/90 min (1,200/1,560 THB)",
      detail:
        "A proven technique for soothing away tensions in the upper body, breaking down knots and accentuating flexibility. By stimulating nerves and blood circulation around the scalp, the technique also brings headache relief. Sweet release results.",
    },
  ],

  coursesTreatments: [
    {
      course: "KHAO HOM BODY POLISH",
      cost: "60 min (1,800THB)",
      detail:
        "Rice is the root of Thai life and the essence of every meal. Here the fragrant goodness of Khao Hom Mali, known around the world as Jasmine Rice, is the basis of a protein-rich scrub that nourishes and renews the skin through gentle exfoliation. Besides jasmine rice extract, the scrub is blended with rice bran oil, pomelo extract, lotus seed extract and black rice; a potent potion that relaxes as it rejuvenates."
    },
    {
      course: "TEA LEAF BODY SCRUB",
      cost: "60 min (1,800THB)",
      detail:
        "With its anti-inflammatory and antimicrobial properties, tea leaf scrub has long been a popular detox for dead skin as well as a remedy for acne. Blended with green tea extract, longan seed extract, safflower oil, and strawberry seed, this original advanced formula leaves your skin smooth, glowing and clear. "
    },
    {
      course: "SILK BLISS BODY SCRUB",
      cost: "60 min (1,800THB)",
      detail:
        "Silk proteins reduce fine wrinkles by smoothing, firming and increasing elasticity of the skin. This treatment optimizes skin texture and hydration. Golden silk blended with tamarind extract, Indian gooseberry extract, mulberry and luffa, offers a moisturizing, anti-aging, and anti-pollution solution to general wear and tear. Your skin is ultimately restored to its natural radiance."
    },
    {
      course: "ALGAE BODY SCRUB",
      cost: "60 min (1,800THB)",
      detail:
        "Algae extracts, cashew nut extract and coconut shell sand packed with vitamins and minerals proves a potent protection formula for your skin. Algae is also rich in anti-oxidants that disarm free radicals that are the primary cause of aging. Better yet, algae boosts the body’s collagen and elastin production, winning the war against wrinkles. Taken altogether, it leaves your skin toned, firmer and healthier."
    },
    {
      course: "WHITE ORCHID BODY MASK",
      cost: "60 min (1,800THB)",
      detail:
        "Enriched with extracts of white orchid, gold leaf powder, diamond powder and silica, this body mask represents a bar-raising innovation in skin lightening. Antioxidants, multivitamins and beauty enzymes impart a fairer more radiant skin tone. Scientifically refined botanical extracts further lighten and brighten."
    },
  ]
};
