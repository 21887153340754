import { Injectable } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormdataService {

  constructor() { }

  form: UntypedFormGroup = new UntypedFormGroup({
    $key: new UntypedFormControl(null),
    Branch: new UntypedFormControl('', Validators.required),
    Date: new UntypedFormControl('', Validators.required),
    Time: new UntypedFormControl('', Validators.required),
    phone: new UntypedFormControl('', Validators.minLength(9)),
    email: new UntypedFormControl('', Validators.email),
    password: new UntypedFormControl(''),
    isPermanent: new UntypedFormControl(false)
  });

  program: UntypedFormGroup = new UntypedFormGroup({
    $key: new UntypedFormControl(null),
    signature: new UntypedFormControl(''),
    signaturera: new UntypedFormControl(''),
    journey: new UntypedFormControl(''),
    body: new UntypedFormControl(''),
    bodyra: new UntypedFormControl(''),
    facial: new UntypedFormControl(''),
    express: new UntypedFormControl(''),
    nailandhair: new UntypedFormControl(''),
  });

  // insertProgram() {
  //   this.program.push({
  //
  //   });
  // }
}


// gender: new FormControl('1')
