<div class="container">

    <div class="course">{{app.title.toUpperCase()}}</div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[0].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[0].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[0].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[1].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[1].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[1].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[2].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[2].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[2].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[3].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[3].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[3].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[4].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[4].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[4].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[5].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[5].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[5].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[6].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[6].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[6].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[7].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[7].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[7].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesMassage[8].course}}</div>
        <div class="treatmentPrice">{{app.coursesMassage[8].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesMassage[8].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="flexImage">
      <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu7.png" alt="Sense Cera">
      <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu8.png" alt="Sense Cera">
    </div>
  
  </div>
  
  <div class="container">
  
    <div class="course">{{app.title2.toUpperCase()}}</div>
  
    <div class="itemContainer">
  
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesTreatments[0].course}}</div>
        <div class="treatmentPrice">{{app.coursesTreatments[0].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesTreatments[0].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
  
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesTreatments[1].course}}</div>
        <div class="treatmentPrice">{{app.coursesTreatments[1].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesTreatments[1].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
  
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesTreatments[2].course}}</div>
        <div class="treatmentPrice">{{app.coursesTreatments[2].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesTreatments[2].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
  
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesTreatments[3].course}}</div>
        <div class="treatmentPrice">{{app.coursesTreatments[3].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesTreatments[3].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <div class="flexTitle">
        <div class="treatmentName">{{app.coursesTreatments[4].course}}</div>
        <div class="treatmentPrice">{{app.coursesTreatments[4].cost}}</div>
      </div>
  
      <p class="description">
        {{app.coursesTreatments[4].detail}}
      </p>
  
      <img class="separator"
           src="../../../../assets/component/icons/lineflo.png" alt="separator">
    </div>
  
    <div class="itemContainer">
      <img class="singleImage" src="../../../../assets/component/spamenu/spamenu9.png" alt="Sense Cera">
    </div>
  
    <div class="flexImage">
      <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu10.png" alt="Sense Cera">
      <img class="multipleImages" src="../../../../assets/component/spamenu/spamenu11.png" alt="Sense Cera">
    </div>
  </div>
  