<div class="container-fluid background justify-content-center text-center">
  <div class="hide"><br></div>
  <div class="hide"><br></div>
  <div class="main">{{app.title2}}</div>
  <img class="abt1" src={{img[0]}} alt="spoff">
  <div class="hide"><br></div>
  <div class="w3-row">
    <div class="w3-col dissap" style="width:10%"><br></div>
    <div class="w3-col sshow" style="width:2%"><br></div>
    <div class="w3-col dissap" style="width:80%">
      <div class="w3-row">
        <div class="w3-container">
          <owl-carousel-o [options]="spOptions">
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[1]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[2]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[3]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[4]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[5]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[6]}} style="width:100%">
            </ng-template>
          </owl-carousel-o>
        </div>
        <div class="hide"><br></div>
      </div>
    </div>
    <div class="w3-col sshow" style="width:96%">
      <div class="w3-row">
        <div class="w3-container">
          <owl-carousel-o [options]="spOptions">
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[1]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[2]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[3]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[4]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[5]}} style="width:100%">
            </ng-template>
            <ng-template carouselSlide>
              <img fetchpriority="high" src={{img[6]}} style="width:100%">
            </ng-template>
          </owl-carousel-o>
        </div>
        <div class="hide"><br></div>
      </div>
    </div>
    <div class="w3-col dissap" style="width:10%"><br></div>
    <div class="w3-col sshow" style="width:2%"><br></div>
  </div>
  <div><br></div>
  <div><br></div>
</div>

<!--<div class="w3-row">-->
<!--  <div class="w3-container">-->
<!--    <owl-carousel-o [options]="spOptions">-->
<!--      <ng-template carouselSlide>-->
<!--        <img fetchpriority="high" src="/assets/spaimg/web%20design%234-18.png" style="width:100%">-->
<!--      </ng-template>-->
<!--      <ng-template carouselSlide>-->
<!--        <img fetchpriority="high" src="/assets/spaimg/web%20design%234-18.png" style="width:100%">-->
<!--      </ng-template>-->
<!--    </owl-carousel-o>-->
<!--  </div>-->
<!--  <div class="hide"><br></div>-->
<!--</div>-->
