<div class="w3-row background promo">
    <div class="space"></div>

  <div class="w3-row">
    <div class="w3-col w3-container">
      <div class="title">{{app.title2}}</div>
      <div class="container">
        <img class="abt1" src={{img[0]}} alt="promo05">
      </div>
    </div>
  </div>

<section class="padder">
  <img class="imgh" src="/assets/component/promotionApril/Queens/horizontal/aprilhorizontal.jpg" alt="">
</section>

<section class="padder">
  <img class="imgh" src="/assets/component/promotionApril/Queens/horizontal/aprilhorizontal2.jpg" alt="">
</section>

  <div class="container-fluid centered">
    <img class="abt" src="/assets/spaimg/new-title23-bg.png" alt="promo06">
  </div>
</div>