import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, selectAppState } from '../../state/app.state';
// import {translateEN, translateTH} from '../state/translate/translate.actions';

@Component({
  selector: 'app-spa42program',
  templateUrl: './spa42program.component.html',
  styleUrls: ['./spa42program.component.css'],
})
export class Spa42programComponent implements OnInit {
  lang;

  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.app$.subscribe((state) => {
      this.app = state.translate.etc;
    });
  }
  
  changeLang(lang){
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

}
