<h1 mat-dialog-title>Please Select Program</h1>
<div mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Massage">
      <mat-form-field>
        <input matInput [(ngModel)]="data.animal">
      </mat-form-field>
    </mat-tab>
    <mat-tab label="Spa"> Content 2 </mat-tab>
    <mat-tab label="Signature"> Content 3 </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.animal"
          cdkFocusInitial>Ok</button>
</div>
