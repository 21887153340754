export const journeyTh = {
  title: "SENSE JOURNEYS",
  title2: "Bathing Rituals",
  courses: [
    {
      course: "SU  KWAN – JET LAG RECOVERY",
      time: "150 นาที",
      price: "3,840 บาท",
      cost: "150 นาที  3,840 บาท",
      detail: "‘สู่ขวัญ’ หรือ ‘รับขวัญ’ คือหนึ่งในธรรมเนียมแบบดั้งเดิมของทางภาคเหนือที่เอาไว้ใช้ต้อนรับแขกผู้มาเยือนหลังจากที่เดินทางข้ามถิ่นมาไกล เพื่อเยี่ยมเพื่อนหรือครอบครัว พลังของทรีตเมนต์นี้จะช่วยขจัดอาการเหนื่อยล้าจากเจ็ทแลคอย่างตรงจุด ช่วยบรรเทาความเมื่อยตึงของกล้ามเนื้อที่เกิดจากความเครียดขณะเดินทาง ร่างกายและจิตใจของท่านจะได้รับการปลอบประโลมอย่างเต็มที่ เริ่มต้นด้วยการแช่ตัวใน Aromatic Bath ตามด้วยการขัดผิว  การนวดขจัดเจ็ทแลค และนำมาสู่ช่วงไฮไลต์คือ แฮร์ทรีตเมนต์ เพื่อความผ่อนคลายตั้งแต่ศีรษะจรดปลายเท้า และความรู้สึกปลดล็อกทั้งทางกายและใจ",
    },
    {
      course: "LANGKA - SUKA",
      time: "180 นาที",
      price: "5,040 บาท",
      cost: "180 นาที  5,040 บาท",
      detail: "ทรีตเมนต์ตามแบบฉบับของทางใต้ เริ่มต้นด้วย Himalayan Salt Sauna หรือซาวน่าในห้องเกลือหิมาลายัน การขัดและผลัดเซลล์ผิวด้วยสมุนไพร จากภาคใต้ ต่อเนื่องด้วย การนวดแบบ Langka-Suka เทคนิคพิเศษที่เริ่มต้นด้วยการนวดบริเวณช่วงท้อง การกดจุดประสานกับการใช้ลูกประคบสมุนไพรและน้ำมันอุ่นร้อนสูตรพิเศษที่กลั่นจากสารสกัดจากสมุนไพรจากภาคใต้กับน้ำมันเทียนดำ มีคุณสมบัติช่วยในเรื่องของการไหลเวียนโลหิตและคลายความตึงของกล้ามเนื้อ ปิดท้ายด้วย ทรีตเมนต์สำหรับผิวหน้า ที่เป็นขั้นตอนที่ช่วยคืนความอ่อนเยาว์และกระจ่างใสให้กับผิวของท่าน ",
    },
    {
      course: "CHEEVIT CHEEVA ISAN",
      time: "150 นาที",
      price: "3,840 บาท",
      cost: "150 นาที  3,840 บาท",
      detail: "ประสบการณ์ที่รอคอยท่านอยู่ในทรีตเมนต์นี้จะช่วยพลิกฟื้นร่างกายและจิตวิญญาณอย่างสมบูรณ์แบบ โปรแกรมจะเริ่มต้นด้วย การแช่เท้าในน้ำสมุนไพร ที่มีส่วนผสมของเกลือทะเล ชาเขียว และสารสกัดจากองุ่น ช่วยลดอาการบวมและกระตุ้นการไหลเวียนของเลือดให้ดียิ่งขึ้น ตามด้วย การแช่ตัวใน Aromatic Bath การขัดผิวด้วย มัลเบอร์รี่สครับ และรังไหมเพื่อทำความสะอาดผิวขั้นล้ำลึก มาส์กผิวเพื่อความเปล่งปลั่งด้วย บอดี้มาส์กจากดอกกล้วยไม้ขาว และปิดท้ายด้วยการนวดพร้อมยืดเหยียดโดยการใช้ ‘ผ้าขาวม้า’ ผสานกับท่านวดแผนโบราณ เพื่อบริหารกล้ามเนื้อพร้อมคลายปมและพังผืดที่เป็นสาเหตุของความเมื่อยล้า โดยไม่ทำให้รู้สึกเจ็บปวดหรือไม่สบายกาย",
    },
    {
      course: "PIAMSUK",
      time: "180 นาที",
      price: "5,040 บาท",
      cost: "180 นาที  5,040 บาท",
      detail: "ทรีตเมนต์ที่เริ่มต้นด้วย Himalayan Salt Sauna ซาวน่าในห้องเกลือหิมาลายันเพื่อกระตุ้นระบบไหลเวียนโลหิต ตามด้วยการขัดผิวด้วย บอดี้สครับข้าวหอมมะลิ ผสมสารสกัดจากส้มโอ เม็ดบัว และน้ำมันรำข้าว ช่วยเพิ่มความชุ่มชื้นและคืนความสดชื่นให้กับสัมผัสทั่วร่างกาย จากนั้นจะนำท่านเข้าสู่ การนวดสวีดิช เพื่อคลายกล้ามเนื้อในจุดต่าง ๆ และการนวดเท้าเพื่อปรับสมดุลพร้อมกระตุ้นการไหลเวียนภายในร่างกายให้ดียิ่งขึ้น ปิดท้ายด้วย ทรีตเมนต์ผิวหน้าสูตรเพิ่มความชุ่มชื่น ที่จะคืนความเปล่งปลั่งและกระจ่างใสให้กับใบหน้าของท่าน",
    },
    {
      course: "SABAI KAI SABAI  JAI ",
      time: "240 นาที",
      price: "6,480 บาท",
      cost: "240 นาที  6,480 บาท",
      detail: "โปรแกรมซิกเนเจอร์ของการนวดที่เป็นเอกลักษณ์เฉพาะตัวของ Sense Cera ออกแบบมาเพื่อสร้างความผ่อนคลาย และ เพิ่มเอเนอร์จีให้กับร่างกายและจิตใจอย่างเปี่ยมไปด้วยพลัง เริ่มต้นขั้นตอนแรกด้วย การแช่น้ำนม เพื่อกระตุ้นการไหลเวียนของเลือด ตามด้วย บอดี้สครับ เพื่อทำความสะอาดผิวอย่างล้ำลึก และ บอดี้มาส์ก เพื่อเติมความชุ่มชื่น ความเรียบเนียน คืนความสดใสให้ผิวรู้สึกสดชื่น ก่อนนำท่านเข้าสู่ ทรีตเมนต์ผิวหน้า ที่ปลอบประโลมดูแลผิวส่วนที่เปราะบางให้เนียนและนุ่มยิ่งขึ้น และจบด้วย แฮร์ทรีตเมนต์ ที่ช่วยคืนความเปล่งปลั่งให้กับเส้นผม",
    },

  ],
  bathing: [
    {
      course: "AROMATIC FLOWER BATH",
      cost: "30 นาที (850 บาท)",
      detail: "ปรนเปรอตัวเองกับการแช่ตัวในอ่างน้ำที่เต็มไปด้วยสีสันและกลิ่นหอมของดอกไม้นานาพรรณ ช่วยปลอบประโลมจิตใจในวันที่เหนื่อยล้าและบำรุงผิวให้เนียนนุ่มยิ่งขึ้น",
    },
    {
      course: "THAI HERBAL BATH",
      cost: "30 นาที (850 บาท)",
      detail: "การแช่น้ำเพื่อฟื้นฟู ผสานพลังการรักษาของสมุนไพรไทยและคุณสมบัติในการขจัดสารพิษของเกลือทะเล ช่วยกำจัดของเสียในร่างกายและยกระดับความสงบของจิตใจ",
    },
    {
      course: "MILK BATH",
      cost: "30 นาที (850 บาท)",
      detail: "หย่อนกายแช่ตัวใน Milk Bath น้ำนมเข้มข้นสูตรลับที่เข้าบำรุงผิวให้ชุ่มชื่นอย่างยาวนาน ผ่อนคลายได้ยิ่งกว่าด้วยกลิ่นหอมของกลีบดอกกุหลาบที่ลอยอยู่บนฟองน้ำนม",
    },
  ]
}
