import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import {FormdataService} from '../shared/formdata.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-userprograms',
  templateUrl: './userprograms.component.html',
  styleUrls: ['./userprograms.component.css']
})
export class UserprogramsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserprogramsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public service: FormdataService
  ) {
    this.form = this.fb.group({
      checkArray: this.fb.array([])
    });
  }

  // @Input() childPosts: any[]= [];

  form: UntypedFormGroup;

  SProgram: Array<any> = [
    { pname: 'PINDA SWEDANA MASSAGE', about: 'A traditional Ayurvedic therapy.',  value: 'PINDA SWEDANA MASSAGE'},
    { pname: 'SIAM BLENDED MASSAGE', about: 'A combination of four massage techniques.', value: 'SIAM BLENDED MASSAGE'},
    { pname: 'AYURVEDIC ABHYANGA MASSAGE', about: 'A blissfully relaxing massage with warm oil.', value: 'AYURVEDIC ' +
        'ABHYANGA MASSAGE'},
  ];
  RAtime: Array<any> = [
    { amount: '2200 THB', time: '120 Min', value: '(2200 THB/120 min)'},
    { amount: '1800 THB', time: '90 Min', value: '(1800 THB/90 min)'}
  ];

  JProgram: Array<any> = [
    { pname: 'SU KWAN-JET LAG RECOVERY', about: 'A traditional welcoming treatment of northern Thai people.'
      , amount: '3200 THB', time: '150 Min',  value: 'SU KWAN-JET LAG RECOVERY(3200 THB/150 min)'},
    { pname: 'LANGKA-SUKA', about: 'Traditional southern Thai long-term care.', amount: '4200 THB', time: '180 Min'
      , value: 'LANGKA-SUKA(4200 THB/180 min)'},
    { pname: 'CHEEVIT CHEEVA ISAN', about: 'Counters inflammation and improves blood circulation.', amount: '3200 THB'
      , time: '150 Min', value: 'CHEEVIT CHEEVA ISAN(3200 THB/150 min)'},
    { pname: 'PIAMSUK', about: 'Himalayan Salt Sauna promotes circulation and invigorates.', amount: '4200 THB'
      , time: '180 Min', value: 'PIAMSUK(4200 THB/180 min)'},
    { pname: 'SABAI KAI SABAI JAI'
      , about: 'This signature classic relaxing massage promotes a powerful sense of wellbeing.', amount: '5400 THB'
      , time: '240 Min', value: 'SABAI KAI SABAI JAI(5400 THB/240 min)'}
  ];

  BM1Program: Array<any> = [
    { pname: 'JET-LAG RECOVERY MASSAGE', about: 'To relieving travel stress from long-haul flights.'
      ,  value: 'JET-LAG RECOVERY MASSAGE'},
    { pname: 'AROMA THERAPY MASSAGE', about: 'To relax the body and awaken the mind.',  value: 'AROMA THERAPY MASSAGE'},
  ];
  BM1RAtime: Array<any> = [
    { amount: '1600 THB', time: '90 Min', value: '(1600 THB/90 min)'},
    { amount: '1200 THB', time: '60 Min', value: '(1200 THB/60 min)'}
  ];
  BM2Program: Array<any> = [
    { pname: 'TRADITIONAL THAI MASSAGE', about: 'Matchless Thai traditional body massage needs no oils or lotions.'
      ,  value: 'TRADITIONAL THAI MASSAGE'},
    { pname: 'ORIENTAL FOOT MASSAGE', about: 'A dynamic treatment mastering the principles of reflexology.'
      ,  value: 'ORIENTAL FOOT MASSAGE'},
    { pname: 'ANTI-STRESS HEAD/BACK/SHOULDER MASSAGE', about: 'A proven technique for soothing away tensions.'
      ,  value: 'ANTI-STRESS HEAD/BACK/SHOULDER MASSAGE'},
  ];
  BM2RAtime: Array<any> = [
    { amount: '1300 THB', time: '90 Min', value: '(1300 THB/90 min)'},
    { amount: '1000 THB', time: '60 Min', value: '(1000 THB/60 min)'}
  ];
  BM3Program: Array<any> = [
    { pname: 'TSWEDISH THAI FUSION MASSAGE', about: 'A deep massage at times bordering on a healing kind of pain.'
      ,  value: 'SWEDISH THAI FUSION MASSAGE'},
  ];
  BM3RAtime: Array<any> = [
    { amount: '2000 THB', time: '90 Min', value: '(2000 THB/90 min)'},
    { amount: '1500 THB', time: '60 Min', value: '(1500 THB/60 min)'}
  ];
  BM4Program: Array<any> = [
    { pname: 'HIMALAYAN SALT STONE MASSAGE', about: 'A deep massage at times bordering on a healing kind of pain'
      ,  value: 'HIMALAYAN SALT STONE MASSAGE'},
  ];
  BM4RAtime: Array<any> = [
    { amount: '2200 THB', time: '120 Min', value: '(2200 THB/120 min)'},
    { amount: '1800 THB', time: '90 Min', value: '(1800 THB/90 min)'}
  ];
  BM5Program: Array<any> = [
    { pname: 'DEEP TISSUE MASSAGE', about: 'Classical Swedish-style massage',  value: 'DEEP TISSUE MASSAGE'}
  ];
  BM5RAtime: Array<any> = [
    { amount: '2500 THB', time: '90 Min', value: '(2500 THB/90 min)'},
    { amount: '1800 THB', time: '60 Min', value: '(1800 THB/60 min)'}
  ];
  BM6Program: Array<any> = [
    { pname: 'THAI HERBAL COMPRESSION ', about: 'Deep tissue techniques combine with a hot herbal compress'
      ,  value: 'THAI HERBAL COMPRESSION '}
  ];
  BM6RAtime: Array<any> = [
    { amount: '1800 THB', time: '120 Min', value: '(1800 THB/90 min)'},
    { amount: '1500 THB', time: '90 Min', value: '(1500 THB/60 min)'}
  ];

  BTProgram: Array<any> = [
    { pname: 'KHAO HOM BODY POLISH'
      , about: 'protein-rich scrub that nourishes and renews the skin through gentle exfoliation.', amount: '1500 THB'
      , time: '60 Min',  value: 'KHAO HOM BODY POLISH(1500 THB/60 min)'},
    { pname: 'TEA LEAF BODY SCRUB', about: 'Original advanced formula leaves your skin smooth, glowing and clear.'
      , amount: '1500 THB', time: '60 Min', value: 'TEA LEAF BODY SCRUB(1500 THB/60 min)'},
    { pname: 'SILK BLISS BODY SCRUB', about: 'Silk proteins reduce fine wrinkles by smoothing, ' +
        'firming and increasing elasticity of the skin. ', amount: '1500 THB', time: '60 Min'
      , value: 'SILK BLISS BODY SCRUB(1500 THB/60 min)'},
    { pname: 'ALGAE BODY SCRUB', about: 'Algae extracts leaves your skin toned, firmer and healthier.'
      , amount: '1500 THB', time: '60 Min', value: 'ALGAE BODY SCRUB(1500 THB/60 min)'},
    { pname: 'WHITE ORCHID BODY MASK', about: 'Algae extracts leaves your skin toned, firmer and healthier.'
      , amount: '1500 THB', time: '60 Min', value: 'WHITE ORCHID BODY MASK(1500 THB/60 min)'},
  ];

  FProgram: Array<any> = [
    { pname: 'PHITOMER Classical Soothing Facial Treatment'
      , about: 'Softens and hydrates sensitive skin and strengthens defenses', amount: '2100 THB', time: '60 Min'
      ,  value: 'PHITOMER Classical Soothing Facial Treatment(2100 THB/60 min)'},
    { pname: 'PHITOMER Moisturizing Facial Treatment'
      , about: 'A skincare essential that pairs moisturizing performance with intense relaxation.'
      , amount: '1800 THB', time: '60 Min', value: 'PHITOMER Moisturizing Facial Treatment(1800 THB/60 min)'},
    { pname: 'PHITOMER Anti-Wrinkle Facial Treatment'
      , about: 'A wrinkle correcting and firming treatment in three steps', amount: '2100 THB', time: '60 Min'
      , value: 'PHITOMER Anti-Wrinkle Facial Treatment(2100 THB/60 min)'},
    { pname: 'PHITOMER White Lumination Facial Treatment'
      , about: 'An ultra-comprehensive anti-aging treatment that brightens skin tone.', amount: '1800 THB'
      , time: '60 Min', value: 'PHITOMER White Lumination Facial Treatment(1800 THB/60 min)'},
    { pname: 'EYES TREATMENT', about: 'A complete and effective radiance and smoothing treatment for beautiful eyes.'
      , amount: '1500 THB', time: '30 Min', value: 'EYES TREATMENT(1500 THB/30 min)'},
    { pname: 'PHITOMER Classic Facial Pour Homme (for men)'
      , about: 'Banish blemishes with this effective flaw-fading men’s facial treatment.', amount: '1800 THB'
      , time: '60 Min', value: 'PHITOMER White Lumination Facial Treatment(1800 THB/60 min)'}
  ];

  EProgram: Array<any> = [
    { pname: 'TENSION RELIEF MASSAGE', about: 'Ideal for those with little time on their hands.', amount: '850 THB'
      , time: '30 Min',  value: 'TENSION RELIEF MASSAGE(850 THB/30 min)'},
    { pname: 'AYURVEDIC HEAD MASSAGE'
      , about: 'This holistic head massage is a healing, rejuvenating and thoroughly stimulating experience.'
      , amount: '850 THB', time: '30 Min',  value: 'AYURVEDIC HEAD MASSAGE(850 THB/30 min)'},
    { pname: 'MINI FACIAL', about: 'Achieving a healthier, smoother complexion.', amount: '850 THB', time: '30 Min'
      ,  value: 'MINI FACIAL(850 THB/30 min)'},
  ];

  AOProgram: Array<any> = [
    { pname: 'Nail Express', about: 'Nail treatment', amount: '490 THB', time: '30 Min'
      ,  value: 'Nail Express(490 THB/30 min)'},
    { pname: 'Gentlemen’s Manicure', about: 'Hair treatment', amount: '590 THB', time: '30 Min'
      ,  value: 'AYURVEDIC HEAD MASSAGE(590 THB/30 min)'},
    { pname: 'Gentlemen’s Pedicure', about: 'Hair treatment', amount: '590 THB', time: '30 Min'
      ,  value: 'MINI FACIAL(590 THB/30 min)'},
    { pname: 'Classic Manicure', about: 'Hair treatment', amount: '890 THB', time: '45 Min'
      ,  value: 'Classic Manicure(890 THB/45 min)'},
    { pname: 'Classic Pedicure', about: 'Hair treatment', amount: '890 THB', time: '45 Min'
      ,  value: 'Classic Pedicure(890 THB/45 min)'},
    { pname: 'Deluxe Manicure', about: 'Hair treatment', amount: '1290 THB', time: '60 Min'
      ,  value: 'Deluxe Manicure(1290 THB/60 min)'},
    { pname: 'Deluxe Pedicure', about: 'Hair treatment', amount: '1290 THB', time: '60 Min'
      ,  value: 'Deluxe Pedicure(1290 THB/60 min)'},
    { pname: 'Hair Treatment and Blow Dry', about: 'Hair treatment', amount: '1290 THB', time: '60 Min'
      ,  value: 'Hair Treatment and Blow Dry(1290 THB/60 min)'}
  ];

  showMe = true;
  showMe1 = true;

  onCheckboxChange(e) {
    const checkArray: UntypedFormArray = this.form.get('checkArray') as UntypedFormArray;

    if (e.target.checked) {
      checkArray.push(new UntypedFormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  submitForm() {
    console.log(this.form.value);
  }

  // hideMe:boolean = false;

  All() {
    this.showMe1 = true;
    this.showMe = true;
    // this.hideMe = !this.hideMe;
  }

  toggleTag() {
    this.showMe1 = false;
    this.showMe = true;
    // this.hideMe = !this.hideMe;
  }

  toggleTag1() {
    this.showMe = false;
    this.showMe1 = true;
  }

  ngOnInit(): void {
  }

}
