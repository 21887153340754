
//ยังไม่มีภาษาไทย ใช้ Eng ไปก่อน

export const overviewTh = {
    title: "สาขา เซนส์ เซร่า ไซมิส สปา",
    branches: [
        {
            branchName: "Wyndham Garden Bangkok Sukhumvit 42",
            subTitle: "Sense Cera Spa",
            openDate: "เปิด 23 ธันวาคม 2565",
            headDescription: "ZEN GARDEN",
            description: {
                line1: "serene places where the mind can be at rest, and you ",
                line2: "can experience a state of calm tranquility."
            },
            textButton: "SPA 42 MENU"
        },
        {
            branchName: "Wyndham Bangkok Queen Convention Centre",
            subTitle: "Sense Cera Spa",
            openDate: "เปิด 16 มีนาคม 256",
            headDescription: "ENGLISH GARDEN",
            description:{
                line1:"light classic element to reminisce the style of the",
                line2:"English garden"
            } ,
            textButton: "QUEEN MENU"
        },
        {
            branchName: "Ramada Plaza by Wyndham Bangkok Sukhumvit 48",
            subTitle: "Sense Cera Spa",
            openDate: "OPEN SOON",
            headDescription: "CITY GARDEN",
            description:{
                line1:"create a new thing with space, a complex structure",
                line2:"which is a result of the interaction between human and his environment."
            },
            textButton: "SPA 48 MENU"
        }
    ]
}
