import { select,Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {AppState, selectAppState} from '../state/app.state';
import {translateEN, translateTH} from '../state/translate/translate.actions';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css'],
})
export class PromotionsComponent implements OnInit {
  img: any = [
  '/assets/spaimg/new-title23-bg.png', 
  './assets/component/promotion-img/Aw-Promotion-01-min.png', 
  "./assets/component/promotion-img/Aw-Promotion-02-min.png", 
  "./assets/component/promotion-img/Aw-Promotion-03-min.png"
]
private $app = this.store.pipe(select(selectAppState))
app:any;

  proOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplayTimeout: 6000,
    autoplay: true,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
    dots: false,
  };

  lang;

  constructor(private store: Store) { }

  ngOnInit() {
    // this.lang = localStorage.getItem('lang') || 'en';
    this.$app.subscribe(state =>{
      this.app = state.translate.promotions
      console.log(this.app);
      
    })
  }
  changeLang(lang) {
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
}
