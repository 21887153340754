import {Component, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectAppState} from '../state/app.state';
import {translateEN, translateTH} from '../state/translate/translate.actions';
import {setLanding} from '../state/config/config.actions';
import {Router} from '@angular/router';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {

  @Input() deviceXs: boolean;
  showFiller = false;
  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(
    private store: Store<AppState>
  ) {

  }
  ngOnInit() {
    this.app$.subscribe(state => this.app = state);
  }

  async translate() {
    if (this.app.translate.th) {
      this.store.dispatch(translateEN());
    } else {
      this.store.dispatch(translateTH());
    }
  }
}
