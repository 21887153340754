import { select,Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import {AppState, selectAppState} from '../state/app.state';
import {translateEN, translateTH} from '../state/translate/translate.actions';

@Component({
  selector: 'app-qpromotions',
  templateUrl: './qpromotions.component.html',
  styleUrls: ['./qpromotions.component.css']
})
export class QpromotionsComponent implements OnInit {

  img: any = [
    '/assets/spaimg/new-title23-bg.png', 
    './assets/component/promotion-img/Aw-Promotion-01-min.png', 
    "./assets/component/promotion-img/Aw-Promotion-02-min.png", 
    "./assets/component/promotion-img/Aw-Promotion-03-min.png"
  ]
  private $app = this.store.pipe(select(selectAppState))
  app:any;
  lang;

  constructor(private store: Store) { }

  ngOnInit() {
    // this.lang = localStorage.getItem('lang') || 'en';
    this.$app.subscribe(state =>{
      this.app = state.translate.promotions
      console.log(this.app);
      
    })
  }
  changeLang(lang) {
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
}
