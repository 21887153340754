import {ConfigInit} from './config.init';
import {createReducer, on} from '@ngrx/store';
import {
  setLanding,
  resetLanding
} from './config.actions';

export const configReducer = createReducer(
  ConfigInit,

  on(setLanding, (state) => ({

    ...state,
    isLandingPage: true
  })),

  on(resetLanding, (state) => ({
    ...state,
    isLandingPage: false
  })),
);
