import {Component, OnInit} from '@angular/core';
import {signatureEn} from '../../../state/languages/SpaMenu/signature/signatureEn.js';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {
  app: any;

  constructor() {
    this.app = signatureEn;
  }

  ngOnInit(): void {
  }

}
