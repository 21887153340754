import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectAppState} from '../../state/app.state';

@Component({
  selector: 'app-layout-switching',
  templateUrl: './layout-switching.component.html',
  styleUrls: ['./layout-switching.component.css']
})
export class LayoutSwitchingComponent implements OnInit {

  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(
    private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.app$.subscribe(state => {
      this.app = state;
    });
  }
}
