<div class="container-fluid background justify-content-center text-center">
  <div class="w3-row">
    <div class="space"></div>
    <div class="w3-col w3-container">
      <div class="title">{{app.translate.branch.title}}</div>
      <div class="container">
        <img class="abt1" src="/assets/spaimg/new-title23-bg.png" alt="promo05">
      </div>
    </div>
  </div>
  <br>
  <div class="w3-row">
    <div class="w3-container w3-half centered">
      <br>
      <div>
        <div>
          <img class="mySlides image1" src="./assets/spaimg/Spa%2042_Foot%20massage.png" style="display:none">
          <img class="mySlides image1" src="./assets/spaimg/Spa%2042_Lobby.png" style="display:block">
          <img class="mySlides image1" src="./assets/spaimg/Spa%2042_Foot%20massage.png" style="display:none">
        </div>
      </div>
      <br>
    </div>
    <div class="w3-container w3-half mgr">
      <div class="container-lg frame">
        <div class="showww bye"><br></div>
        <h1 class="main1">{{app.translate.branch.branches[0].branchName}}</h1>
        <h5 class="sub">{{app.translate.branch.branches[0].subTitle}}</h5>
        <h4 class="openDay">"{{app.translate.branch.branches[0].openDate}}"</h4>
        <mat-divider class="divider1"></mat-divider>
        <p class="text hide">
          <span class="sub1">{{app.translate.branch.branches[0].headDescription}}</span>
          {{app.translate.branch.branches[0].description.line1}}
        </p>
        <div class="text1 hide">{{app.translate.branch.branches[0].description.line2}}</div>
        <div class="text2 show">
          <span class="sub1">{{app.translate.branch.branches[0].headDescription}}</span>
          {{app.translate.branch.branches[0].description.line1}}{{app.translate.branch.branches[0].description.line2}}
        </div>
               <div class="row centered" *ngIf="!deviceXs">
                 <img class="imgfac" src="./assets/component/icons/facilities.png" alt="">
                 <button class="button2 centered" mat-button [matMenuTriggerFor]="menu">
                  {{app.translate.branch.branches[0].textButton}}
                </button>
                  <mat-menu #menu="matMenu">
                    <a mat-menu-item target="_blank" href="https://anyflip.com/lxfbh/dutp/">English Version</a>
                    <a mat-menu-item target="_blank" href="https://anyflip.com/emscz/dnwz/">Thai version</a>
                    <a mat-menu-item target="_blank" href="https://anyflip.com/lxfbh/jaba/">Japan version</a>
                  </mat-menu>
               </div>

      </div>
      <div class="w3-container marleft">
        <div class="w3-row-padding w3-section pdno">
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Spa%2042_Foot%20massage.png"
                 style="cursor:pointer" onclick="currentDiv(1)">
          </div>
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Spa%2042_Lobby.png"
                 style="cursor:pointer" onclick="currentDiv(2)">
          </div>
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Spa%2042_Foot%20massage.png"
                 style="cursor:pointer" onclick="currentDiv(3)">
          </div>
        </div>
      </div>
    </div>
    <br>
  </div>
  <br>
  <div class="show">
    <div class="container-fluid centered">
      <img class="line" src="./assets/component/icons/decline.png" alt="promo06">
    </div>
  </div>
  <br>

  <div class="w3-row show">
    <div class="w3-container w3-half centered">
      <br>
      <div>
        <div>
          <img class="mySlides1 image1" src="./assets/spaimg/Treatment%20room.png" style="display:none">
          <img class="mySlides1 image1" src="./assets/spaimg/Queen_Lobby.png" style="display:block">
          <img class="mySlides1 image1" src="./assets/spaimg/Thai%20massage.png" style="display:none">
        </div>
      </div>
      <br>
    </div>
    <div class="w3-container w3-half">
      <div class="container-lg frame">
        <h1 class="main1">{{app.translate.branch.branches[1].branchName}}</h1>
        <h5 class="sub">{{app.translate.branch.branches[1].subTitle}}</h5>
        <h4 class="openDay">"{{app.translate.branch.branches[1].openDate}}"</h4>
        <mat-divider></mat-divider>
        <p class="text hide">
          <span class="sub1">{{app.translate.branch.branches[1].headDescription}}</span>
          {{app.translate.branch.branches[1].description.line1}}
        </p>
        <div class="text1 hide">{{app.translate.branch.branches[1].description.line2}}</div>
        <div class="text2 show">
          <span class="sub1">{{app.translate.branch.branches[1].headDescription}}</span>
          {{app.translate.branch.branches[1].description.line1}}
          {{app.translate.branch.branches[1].description.line2}}
        </div>

              <div class="row centered" *ngIf="!deviceXs">
                <img class="imgfac" src="./assets/component/icons/facilities.png" alt="">
                <button class="button2 centered" mat-button [matMenuTriggerFor]="menu">
                  {{app.translate.branch.branches[1].textButton}}
               </button>
                 <mat-menu #menu="matMenu">
                   <a mat-menu-item target="_blank" href="https://anyflip.com/emscz/rnvs/">English Version</a>
                   <a mat-menu-item target="_blank" href="https://anyflip.com/emscz/lhaa/">Thai version</a>
                 </mat-menu>
              </div>

      </div>
      <div class="w3-container marleft">
        <div class="w3-row-padding w3-section pdno">
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Treatment%20room.png"
                 style="cursor:pointer" onclick="currentDiv1(1)">
          </div>
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Queen_Lobby.png"
                 style="cursor:pointer" onclick="currentDiv1(2)">
          </div>
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Thai%20massage.png"
                 style="cursor:pointer" onclick="currentDiv1(3)">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w3-row hide">
    <div class="w3-container w3-half">
      <div class="container-lg frame">
        <h1 class="main1">{{app.translate.branch.branches[1].branchName}}</h1>
        <h5 class="sub">{{app.translate.branch.branches[1].subTitle}}</h5>
        <h4 class="openDay">"{{app.translate.branch.branches[1].openDate}}"</h4>
        <mat-divider></mat-divider>
        <p class="text">
          <span class="sub1">{{app.translate.branch.branches[1].headDescription}}</span>
          {{app.translate.branch.branches[1].description.line1}}
        </p>
        <div class="text1">{{app.translate.branch.branches[1].description.line2}}</div>

               <div class="row centered" *ngIf="!deviceXs">
                <img class="imgfac" src="./assets/component/icons/facilities.png" alt="">
                <button class="button2 centered" mat-button [matMenuTriggerFor]="menu">
                  {{app.translate.branch.branches[1].textButton}}
               </button>
                 <mat-menu #menu="matMenu">
                   <a mat-menu-item target="_blank" href="https://anyflip.com/emscz/rnvs/">English Version</a>
                   <a mat-menu-item target="_blank" href="https://anyflip.com/emscz/lhaa/">Thai version</a>
                 </mat-menu>
              </div>

      </div>
      <div class="w3-container">
        <div class="w3-row-padding w3-section pdno">
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Treatment%20room.png"
                 style="cursor:pointer" onclick="currentDiv3(1)">
          </div>
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Queen_Lobby.png"
                 style="cursor:pointer" onclick="currentDiv3(2)">
          </div>
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/spaimg/Thai%20massage.png"
                 style="cursor:pointer" onclick="currentDiv3(3)">
          </div>
        </div>
      </div>
    </div>
    <div class="w3-container w3-half centered">
      <div>
        <div>
          <img class="mySlides3 image1" src="./assets/spaimg/Treatment%20room.png" style="display:none">
          <img class="mySlides3 image1" src="./assets/spaimg/Queen_Lobby.png" style="display:block">
          <img class="mySlides3 image1" src="./assets/spaimg/Thai%20massage.png" style="display:none">
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="show">
    <div class="container-fluid centered">
      <img class="line" src="./assets/component/icons/decline.png" alt="promo06">
    </div>
  </div>
  <br>

  <div class="w3-row">
    <div class="w3-container w3-half centered">
      <br>
      <div>
        <div>
          <img class="mySlides2 image1" src="./assets/component/spa48/Spa 48_Lobby 2.png" style="display:block">
          <img class="mySlides2 image1" src="./assets/component/spa48/Spa 48_Lobby 1.png" style="display:none">
          <img class="mySlides2 image1" src="./assets/component/spa48/Spa 48_Lobby 3.png" style="display:none">
        </div>
      </div>
      <br>
    </div>
    <div class="w3-container w3-half mgr">
      <div class="container-lg frame">
        <div class="showww bye"><br></div>
        <h1 class="main1">{{app.translate.branch.branches[2].branchName}}</h1>
        <h5 class="sub">{{app.translate.branch.branches[2].subTitle}}</h5>
        <h4 class="openDay">"{{app.translate.branch.branches[2].openDate}}"</h4>
        <mat-divider class="divider1"></mat-divider>
        <p class="text hide">
          <span class="sub1">{{app.translate.branch.branches[2].headDescription}}</span>
          {{app.translate.branch.branches[2].description.line1}}
        </p>
        <div class="text1 hide">
          {{app.translate.branch.branches[2].description.line2}}
        </div>
        <div class="text2 show">
          <span class="sub1">{{app.translate.branch.branches[2].headDescription}}</span>
          {{app.translate.branch.branches[2].description.line1}}
          {{app.translate.branch.branches[2].description.line2}}
        </div>

               <div class="row centered" *ngIf="!deviceXs">
                <img class="imgfac" src="./assets/component/icons/facilities.png" alt="">
                <button class="button2 centered" mat-button [matMenuTriggerFor]="menu">
                  {{app.translate.branch.branches[2].textButton}}
               </button>
                 <mat-menu #menu="matMenu">
                   <a mat-menu-item target="_blank" href="https://anyflip.com/emscz/rnvs/">English Version</a>
                   <a mat-menu-item target="_blank" href="https://anyflip.com/emscz/lhaa/">Thai version</a>
                 </mat-menu>
              </div>

      </div>
      <div class="w3-container marleft">
        <div class="w3-row-padding w3-section pdno">
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/component/spa48/Spa 48_Lobby 2.png"
                 style="cursor:pointer" onclick="currentDiv2(1)">
          </div>
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/component/spa48/Spa 48_Lobby 1.png"
                 style="cursor:pointer" onclick="currentDiv2(2)">
          </div>
          <div class="w3-col s4">
            <img class="demo w3-opacity w3-hover-opacity-off img" src="./assets/component/spa48/Spa 48_Lobby 3.png"
                 style="cursor:pointer" onclick="currentDiv2(3)">
          </div>
        </div>
      </div>
    </div>
    <br>
  </div>
  <br>
  <br>
</div>
