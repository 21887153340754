import {Component, OnInit} from '@angular/core';
import {expressEn} from '../../../state/languages/SpaMenu/express/expressEn.js';

@Component({
  selector: 'app-express',
  templateUrl: './express.component.html',
  styleUrls: ['./express.component.css']
})
export class ExpressComponent implements OnInit {

  app: any;

  constructor() {
    this.app = expressEn;
  }

  ngOnInit(): void {
  }

}
