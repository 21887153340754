<mat-toolbar-row class="toolbar2 none">
  <div class="w3-row centered">
    <a class="a" routerLink="/">
        <span class="a" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">{{app.translate.header.home}}</span>
    </a>
    <div class="vl"></div>
    <a class="a" routerLink="/branches">
      <span class="a" routerLinkActive="active">{{app.translate.header.branches}}</span>
    </a>
    <div class="vl"></div>
    <a class="b a">
      <div [matMenuTriggerFor]="menu" class="flex">
        <div>{{app.translate.header.menus}}</div>
        <mat-icon>expand_more</mat-icon>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/spa42menu">
          <mat-icon class="icon">spa</mat-icon>
          <span>Sukhumvit 42 Menu</span>
        </button>
        <button mat-menu-item routerLink="/spaqueenmenu">
          <mat-icon class="icon">spa</mat-icon>
          <span>Queen Menu</span>
        </button>
        <button mat-menu-item routerLink="/spa48menu">
          <mat-icon class="icon">spa</mat-icon>
          <span>Sukhumvit 48 Menu</span>
        </button>
      </mat-menu>
    </a>
    <div class="vl"></div>
    <a class="b a">
      <div [matMenuTriggerFor]="menup" class="flex">
        <div>{{app.translate.header.promotions}}</div>
        <mat-icon>expand_more</mat-icon>
      </div>
      <mat-menu #menup="matMenu">
        <button mat-menu-item routerLink="/s42promotion">
          <mat-icon class="icon">spa</mat-icon>
          <span>Sukhumvit 42 Promotions</span>
        </button>
        <button mat-menu-item routerLink="/qpromotion">
          <mat-icon class="icon">spa</mat-icon>
          <span>Queen Promotions</span>
        </button>
      </mat-menu>
    </a>
    <div class="vl"></div>
    <a class="a" routerLink="/about">
      <span class="a" routerLinkActive="active">{{app.translate.header.about}}</span>
    </a>
    <div class="vl"></div>
    <a class="a" routerLink="/contactus">
      <span class="a" routerLinkActive="active">{{app.translate.header.contact}}</span>
    </a>
  </div>
</mat-toolbar-row>
