export const contactUsTh = {
  title: "ติดต่อเรา",
  SenseCeraSpa:"SENSE CERA SPA",
  branches: [
    {
      name: 'Wyndham Garden Bangkok Sukhumvit 42',
      image: '../assets/spaimg/Spa 42_Lobby.png',
      address: '19 สุขุมวิท 42 แขวงพระโขนง เขตคลองเตย กรุงเทพมหานคร 10110',
      address1: '19 สุขุมวิท 42 แขวงพระโขนง',
      address2: 'เขตคลองเตย กรุงเทพมหานคร 10110',
      phone: 'Tel. +66 (0)2 331 1022',
      website: 'website: www.sw.com',
      email: 'e-mail: resv42@sw.co.th',
      line: 'Line ID: SiameseWellness',
      map: '../assets/spaimg/Wyndham-42-map.png',
    },
    {
      name: 'Wyndham Bangkok Queen Convention Center',
      image: '../assets/spaimg/Queen_Lobby.png',
      address: '388 ซอย ไผ่สิงโต แขวงคลองเตย เขตคลองเตย กรุงเทพมหานคร 10110',
      address1: '388 ซอย ไผ่สิงโต แขวงคลองเตย',
      address2: 'เขตคลองเตย กรุงเทพมหานคร 10110',
      phone: 'Tel. +66 (0)2 331 2442',
      website: 'website: www.sw.com',
      email: 'e-mail: resvqueen@sw.co.th',
      line: 'Line ID: SiameseWellness',
      map: '../assets/spaimg/Wyndham-queen-map.png',
    },
    {
      name: 'Wyndham Garden Bangkok Sukhumvit 48',
      image: '../assets/component/spa48/Spa 48_Lobby 2.png',
      address: '1448 ซอยสุขุมวิท 48 แขวงพระโขนง เขตคลองเตย  กรุงเทพมหานคร 10110',
      address1: '1448 ซอยสุขุมวิท 48 แขวงพระโขนง',
      address2: 'เขตคลองเตย  กรุงเทพมหานคร 10110',
      phone: 'Tel. +66 (0)2 331 1066',
      website: 'website: www.sw.com',
      email: 'e-mail: resv48@sw.co.th',
      line: 'Line ID: SiameseWellness',
      map: '../assets/spaimg/Wyndham-48-map.png',
    }
  ]
}
