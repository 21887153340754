import {Component, OnInit, Input} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectAppState} from '../state/app.state';
import {translateEN, translateTH} from '../state/translate/translate.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() deviceXs: boolean;
  showFiller = false;

  private app$ = this.store.pipe(select(selectAppState));
  app: any;
TH: any;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.app$.subscribe(state => {
      this.app = state;
    });
  }

  async translate() {
    if (this.app.translate.th) {
      this.store.dispatch(translateEN());
    } else {
      this.store.dispatch(translateTH());
    }
  }
}
