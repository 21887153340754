import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppState, selectAppState} from '../state/app.state';
import {setLanding} from '../state/config/config.actions';

@Component({
  selector: 'app-promolanding',
  templateUrl: './promolanding.component.html',
  styleUrls: ['./promolanding.component.css']
})
export class PromolandingComponent implements OnInit {

  private app$ = this.store.pipe(select(selectAppState));
  app: any;

  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
  }

  gotopage(pageName: string): void {
    this.router.navigate(['${}']);
  }

  ngOnInit(): void {
    this.store.dispatch(setLanding())
  }
}
