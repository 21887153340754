export const etcEn = {
  branch: {
    common: "SENSE CERA SIAMESE SPA",
    s42: "Siamese Sukhumvit 42",
    queen: "Siamese Exclusive Queen",
    s48: "Siamese Sukhumvit 48"
  },
  titleBar: "SENSE CERA SPA PROGRAMS",
  navBar: {
    signature: "SIGNATURE",
    journey: "JOURNEY",
    body: "BODY",
    facial: "FACIAL",
    express: "EXPRESS",
    hairAndNails: "HAIR & NAILS"
  }
}
