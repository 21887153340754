export const aboutEn = {
  title: "ABOUT",
  subtitle: "SENSE CERA SIAMESE SPA",
  content: {
    paragraph1en: "WELCOME TO A WORLD OF WELLBEING",
    paragraph2en: "A sanctuary of vitality awaits, inviting you to relax, refresh and re-balance step into paradise on earth and be transported to an holistic realm of well being. Having mapped your journey in consultation with your intensively trained therapist, your personalized mind- and body-awakening and soul-stirring voyage of discovery begins.",
    paragraph3en: "Treat yourself to a blissful pampering, deep re-balancing and exceptional beautification techniques. Discover a spa for health lovers and impression makers wise to the rejuvenating powers of nature. Your mind, body and soul are set to feel as vital, healthy and free as can be. Let mindfulness and wellbeing wisdom weave their spell to replenish your senses.",
    paragraph4en: "REJUVENATION AND WELLBEING BEYOND THE ORDINARY."
  }
}
