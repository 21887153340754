export const expressTh ={
    title: "Express Spa",
    courses: [
      {
        course: "TENSION RELIEF MASSAGE	",
        cost: "30 นาที (850 บาท)",
        detail: "การนวดอย่างเข้มข้นที่มุ่งเป้าไปที่แผ่นหลัง คอ  บ่า และไหล่ เพื่อบรรเทาอาการเจ็บปวดหรือเมื่อยล้า เห็นผลได้ในทันทีและเหมาะสำหรับคนที่มีเวลาจำกัด",
      },
      {
          course: "AYURVEDIC HEAD MASSAGE",
          cost: "30 นาที (850 บาท)",
          detail: "โปรแกรมการนวดศีรษะแบบอายุรเวทที่ช่วยบำบัดและฟื้นฟูร่างกายโดยการกดจุดสำคัญบริเวณศีรษะ ใบหน้า แผ่นหลังท่อนบน คอ บ่า และไหล่ ช่วยทำให้ตื่นตัวและเสริมสมาธิ",
      },
      {
          course: "MINI FACIAL",
          cost: "30 นาที (850 บาท)",
          detail: "เทคนิคการนวดหน้าแบบลับเฉพาะที่ช่วยปรับผิวให้สุขภาพดีขึ้นและเรียบเนียนมากยิ่งขึ้น",
      },
    ],
}