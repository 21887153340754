import { selectAppState } from './../state/app.state';
import {select, Store} from '@ngrx/store';
import {Component, Input, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-special-offer',
  templateUrl: './special-offer.component.html',
  styleUrls: ['./special-offer.component.css']
})
export class SpecialOfferComponent implements OnInit {
  img:any = [
    "/assets/spaimg/new-title23-bg.png",
    "/assets/spaimg/new-coupon.png",
    "/assets/spaimg/new-coupon-primary.png",
    "/assets/spaimg/new-coupon-premier.png",
    "/assets/spaimg/new-coupon-preffered.png",
    "/assets/spaimg/new-coupon-prime.png",
    "/assets/spaimg/new-coupon-prestige.png"
  ]
  private $app = this.store.pipe(select(selectAppState))
  app:any;

  spOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplayTimeout: 3000,
    autoplay: true,
    navSpeed: 100,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };

  lang;

  constructor(private store : Store) {
  }

  ngOnInit() {
    // this.lang = localStorage.getItem('lang') || 'en';
    this.$app.subscribe((state) =>{
      this.app = state.translate.promotions
    })
  }
  changeLang(lang){
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
}
