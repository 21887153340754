<div class="container">
  <!-- <div class="course">"NOT AVALIABLE FOR NOW"</div>
  </div> -->

    <div class="course">{{app.title.toUpperCase()}}</div>
    
    <div class="itemContainer">
      <div class="grid flex">
        <div class="sm:col-8 col-6 contentl">{{app.courses[0].course}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[0].time}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[0].price}}</div>
      </div>
    </div>

    <div class="itemContainer">
      <div class="grid flex">
        <div class="sm:col-8 col-6 contentl">{{app.courses[1].course}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[1].time}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[1].price}}</div>
      </div>
    </div>

    <div class="itemContainer">
      <div class="grid flex">
        <div class="sm:col-8 col-6 contentl">{{app.courses[2].course}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[2].time}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[2].price}}</div>
      </div>
    </div>

    <div class="itemContainer">
      <div class="grid flex">
        <div class="sm:col-8 col-6 contentl">{{app.courses[3].course}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[3].time}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[3].price}}</div>
      </div>
    </div>

    <div class="itemContainer">
      <div class="grid flex">
        <div class="sm:col-8 col-6 contentl">{{app.courses[4].course}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[4].time}}</div>
        <div class="sm:col-2 col-3 contentr">{{app.courses[4].price}}</div>
      </div>
    </div>

    <img class="separator"
      src="../../../../assets/component/icons/lineflo.png" alt="separator">
    
    <div>
      <img class="singleImage"
           src="../../../../assets/component/spamenu/spamenu3.png" alt="Sense Cera">
    </div>
  
    <div class="flexImage">
      <img class="multipleImages"
           src="../../../../assets/component/spamenu/spamenu4.png" alt="Sense Cera">
  
      <img class="multipleImages"
           src="../../../../assets/component/spamenu/spamenu5.png" alt="Sense Cera">
    </div>
  </div>
  
