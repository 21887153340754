<div class="main">SENSE CERA SPA PROGRAMS</div>
<div><br></div>
<mat-tab-group animationDuration="0ms">
  <mat-tab label="SIGNATURE">
    <div><br></div>
    <div>
      <div class="lefty centered">
        <div>sort:</div>
        <button mat-stroked-button class="mgl" (click)="All()">all</button>
      </div>
      <div>
        <div><br></div>
        <div class="course">Signature Programs</div>
        <div><br></div>
        <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
          <ul class="w3-ul">
            <div *ngFor="let SProgram of SProgram; let i=index">
              <li class="w3-bar centered">
                <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="SProgram.value"
                       (change)="onCheckboxChange($event)"/>
                <div class="w3-bar-item onl">
                  <span class="w3-large black">{{SProgram.pname}}</span><br>
                  <span class="gray">{{SProgram.about}}</span>
                </div>
                <div class="col">
                  <div *ngFor="let RAtime of RAtime; let i=index">
                    <div class="w3-right w3-bar-item">
                      <div class="row centered mgr">
                        <div class="col nopb">
                          <input name="gender" class="mgr" type="checkbox" [value]="RAtime.value"
                                 (change)="onCheckboxChange($event)"/>
                        </div>
                        <div class="col wide">
                          <span class="w3-large black">{{RAtime.time}}</span><br>
                          <span class="gray">{{RAtime.amount}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <mat-divider></mat-divider>
            </div>
          </ul>
          <div><br></div>
          <div class="centered righty">
            <button mat-flat-button class="bbbb" type="submit" value="Reset">Reset</button>
            <button mat-flat-button class="bbbb mgl mgr" type="submit" value="Submit">Done</button>
          </div>
        </form>
      </div>
    </div>
    <div><br></div>
  </mat-tab>
  <mat-tab label="JOURNEY">
    <div><br></div>
    <div class="lefty centered">
      <div>sort:</div>
      <button mat-stroked-button class="mgl" (click)="All()">all</button>
    </div>
    <div><br></div>
    <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
      <div class="programs">
        <div class="course">Sense Journeys</div>
        <div><br></div>
        <ul class="w3-ul">
          <div *ngFor="let JProgram of JProgram; let i=index">
            <li class="w3-bar centered">
              <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="JProgram.value"
                     (change)="onCheckboxChange($event)"/>
              <div class="w3-bar-item onl">
                <span class="w3-large black">{{JProgram.pname}}</span><br>
                <span class="gray">{{JProgram.about}}</span>
              </div>
              <div class="col">
                <div class="w3-right w3-bar-item">
                  <div class="row centered mgr">
                    <div class="col wide">
                      <span class="w3-large black">{{JProgram.time}}</span><br>
                      <span class="gray">{{JProgram.amount}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </ul>
      </div>
      <div><br></div>
      <div class="centered righty">
        <button mat-flat-button class="bbbb" type="submit" value="Reset">Reset</button>
        <button mat-flat-button class="bbbb mgl mgr" type="submit" value="Submit">Done</button>
      </div>
    </form>
    <div><br></div>
  </mat-tab>
  <mat-tab label="BODY">
    <div><br></div>
    <div class="lefty centered">
      <div>sort:</div>
      <button mat-stroked-button class="mgl" (click)="All()">all</button>
      <button mat-stroked-button class="mgl" (click)="toggleTag()">massage</button>
      <button mat-stroked-button class="mgl" (click)="toggleTag1()">treatment</button>
    </div>
    <div><br></div>
    <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
      <div class="programs">
        <div *ngIf="showMe">
          <div class="course">Body Massages</div>
          <div><br></div>
          <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
            <ul class="w3-ul">
              <div *ngFor="let BM1Program of BM1Program; let i=index">
                <li class="w3-bar centered">
                  <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="BM1Program.value"
                         (change)="onCheckboxChange($event)"/>
                  <div class="w3-bar-item onl">
                    <span class="w3-large black">{{BM1Program.pname}}</span><br>
                    <span class="gray">{{BM1Program.about}}</span>
                  </div>
                  <div class="col">
                    <div *ngFor="let BM1RAtime of BM1RAtime; let i=index">
                      <div class="w3-right w3-bar-item">
                        <div class="row centered mgr">
                          <div class="col nopb">
                            <input name="gender" class="mgr" type="checkbox" [value]="BM1RAtime.value"
                                   (change)="onCheckboxChange($event)"/>
                          </div>
                          <div class="col wide">
                            <span class="w3-large black">{{BM1RAtime.time}}</span><br>
                            <span class="gray">{{BM1RAtime.amount}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <mat-divider></mat-divider>
              </div>
              <div *ngFor="let BM2Program of BM2Program; let i=index">
                <li class="w3-bar centered">
                  <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="BM2Program.value"
                         (change)="onCheckboxChange($event)"/>
                  <div class="w3-bar-item onl">
                    <span class="w3-large black">{{BM2Program.pname}}</span><br>
                    <span class="gray">{{BM2Program.about}}</span>
                  </div>
                  <div class="col">
                    <div *ngFor="let BM2RAtime of BM2RAtime; let i=index">
                      <div class="w3-right w3-bar-item">
                        <div class="row centered mgr">
                          <div class="col nopb">
                            <input name="gender" class="mgr" type="checkbox" [value]="BM2RAtime.value"
                                   (change)="onCheckboxChange($event)"/>
                          </div>
                          <div class="col wide">
                            <span class="w3-large black">{{BM2RAtime.time}}</span><br>
                            <span class="gray">{{BM2RAtime.amount}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <mat-divider></mat-divider>
              </div>
              <div *ngFor="let BM3Program of BM3Program; let i=index">
                <li class="w3-bar centered">
                  <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="BM3Program.value"
                         (change)="onCheckboxChange($event)"/>
                  <div class="w3-bar-item onl">
                    <span class="w3-large black">{{BM3Program.pname}}</span><br>
                    <span class="gray">{{BM3Program.about}}</span>
                  </div>
                  <div class="col">
                    <div *ngFor="let BM3RAtime of BM3RAtime; let i=index">
                      <div class="w3-right w3-bar-item">
                        <div class="row centered mgr">
                          <div class="col nopb">
                            <input name="gender" class="mgr" type="checkbox" [value]="BM3RAtime.value"
                                   (change)="onCheckboxChange($event)"/>
                          </div>
                          <div class="col wide">
                            <span class="w3-large black">{{BM3RAtime.time}}</span><br>
                            <span class="gray">{{BM3RAtime.amount}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <mat-divider></mat-divider>
              </div>
              <div *ngFor="let BM4Program of BM4Program; let i=index">
                <li class="w3-bar centered">
                  <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="BM4Program.value"
                         (change)="onCheckboxChange($event)"/>
                  <div class="w3-bar-item onl">
                    <span class="w3-large black">{{BM4Program.pname}}</span><br>
                    <span class="gray">{{BM4Program.about}}</span>
                  </div>
                  <div class="col">
                    <div *ngFor="let BM4RAtime of BM4RAtime; let i=index">
                      <div class="w3-right w3-bar-item">
                        <div class="row centered mgr">
                          <div class="col nopb">
                            <input name="gender" class="mgr" type="checkbox" [value]="BM4RAtime.value"
                                   (change)="onCheckboxChange($event)"/>
                          </div>
                          <div class="col wide">
                            <span class="w3-large black">{{BM4RAtime.time}}</span><br>
                            <span class="gray">{{BM4RAtime.amount}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <mat-divider></mat-divider>
              </div>
              <div *ngFor="let BM5Program of BM5Program; let i=index">
                <li class="w3-bar centered">
                  <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="BM5Program.value"
                         (change)="onCheckboxChange($event)"/>
                  <div class="w3-bar-item onl">
                    <span class="w3-large black">{{BM5Program.pname}}</span><br>
                    <span class="gray">{{BM5Program.about}}</span>
                  </div>
                  <div class="col">
                    <div *ngFor="let BM5RAtime of BM5RAtime; let i=index">
                      <div class="w3-right w3-bar-item">
                        <div class="row centered mgr">
                          <div class="col nopb">
                            <input name="gender" class="mgr" type="checkbox" [value]="BM5RAtime.value"
                                   (change)="onCheckboxChange($event)"/>
                          </div>
                          <div class="col wide">
                            <span class="w3-large black">{{BM5RAtime.time}}</span><br>
                            <span class="gray">{{BM5RAtime.amount}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <mat-divider></mat-divider>
              </div>
              <div *ngFor="let BM6Program of BM6Program; let i=index">
                <li class="w3-bar centered">
                  <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="BM6Program.value"
                         (change)="onCheckboxChange($event)"/>
                  <div class="w3-bar-item onl">
                    <span class="w3-large black">{{BM6Program.pname}}</span><br>
                    <span class="gray">{{BM6Program.about}}</span>
                  </div>
                  <div class="col">
                    <div *ngFor="let BM6RAtime of BM6RAtime; let i=index">
                      <div class="w3-right w3-bar-item">
                        <div class="row centered mgr">
                          <div class="col nopb">
                            <input name="gender" class="mgr" type="checkbox" [value]="BM6RAtime.value"
                                   (change)="onCheckboxChange($event)"/>
                          </div>
                          <div class="col wide">
                            <span class="w3-large black">{{BM6RAtime.time}}</span><br>
                            <span class="gray">{{BM6RAtime.amount}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <mat-divider></mat-divider>
              </div>
            </ul>
          </form>
        </div>
        <div *ngIf="showMe1">
          <div><br></div>
          <div class="course">Body Treatments</div>
          <div><br></div>
          <ul class="w3-ul">
            <div *ngFor="let BTProgram of BTProgram; let i=index">
              <li class="w3-bar centered">
                <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="BTProgram.value"
                       (change)="onCheckboxChange($event)"/>
                <div class="w3-bar-item onl">
                  <span class="w3-large black">{{BTProgram.pname}}</span><br>
                  <span class="gray">{{BTProgram.about}}</span>
                </div>
                <div class="col">
                  <div class="w3-right w3-bar-item">
                    <div class="row centered mgr">
                      <div class="col wide">
                        <span class="w3-large black">{{BTProgram.time}}</span><br>
                        <span class="gray">{{BTProgram.amount}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <mat-divider></mat-divider>
            </div>
          </ul>
        </div>
      </div>
      <div><br></div>
      <div class="centered righty">
        <button mat-flat-button class="bbbb" type="submit" value="Reset">Reset</button>
        <button mat-flat-button class="bbbb mgl mgr" type="submit" value="Submit">Done</button>
      </div>
      <div><br></div>
    </form>
  </mat-tab>
  <mat-tab label="FACIAL">
    <div><br></div>
    <div class="lefty centered">
      <div>sort:</div>
      <button mat-stroked-button class="mgl" (click)="All()">all</button>
    </div>
    <div><br></div>
    <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
      <div class="programs">
        <div class="course">Facial Treatments</div>
        <div><br></div>
        <ul class="w3-ul">
          <div *ngFor="let FProgram of FProgram; let i=index">
            <li class="w3-bar centered">
              <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="FProgram.value"
                     (change)="onCheckboxChange($event)"/>
              <div class="w3-bar-item onl">
                <span class="w3-large black">{{FProgram.pname}}</span><br>
                <span class="gray">{{FProgram.about}}</span>
              </div>
              <div class="col">
                <div class="w3-right w3-bar-item">
                  <div class="row centered mgr">
                    <div class="col wide">
                      <span class="w3-large black">{{FProgram.time}}</span><br>
                      <span class="gray">{{FProgram.amount}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </ul>
      </div>
      <div><br></div>
      <div class="centered righty">
        <button mat-flat-button class="bbbb" type="submit" value="Reset">Reset</button>
        <button mat-flat-button class="bbbb mgl mgr" type="submit" value="Submit">Done</button>
      </div>
      <div><br></div>
    </form>
  </mat-tab>
  <mat-tab label="EXPRESS">
    <div><br></div>
    <div>
      <div class="lefty centered">
        <div>sort:</div>
        <button mat-stroked-button class="mgl" (click)="All()">all</button>
      </div>
      <div>
        <div><br></div>
        <div class="course">Express Programs</div>
        <div><br></div>
        <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
          <ul class="w3-ul">
            <div *ngFor="let EProgram of EProgram; let i=index">
              <li class="w3-bar centered">
                <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="EProgram.value"
                       (change)="onCheckboxChange($event)"/>
                <div class="w3-bar-item onl">
                  <span class="w3-large black">{{EProgram.pname}}</span><br>
                  <span class="gray">{{EProgram.about}}</span>
                </div>
                <div class="col">
                  <div class="w3-right w3-bar-item">
                    <div class="row centered mgr">
                      <div class="col wide">
                        <span class="w3-large black">{{EProgram.time}}</span><br>
                        <span class="gray">{{EProgram.amount}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <mat-divider></mat-divider>
            </div>
          </ul>
          <div><br></div>
          <div class="centered righty">
            <button mat-flat-button class="bbbb" type="submit" value="Reset">Reset</button>
            <button mat-flat-button class="bbbb mgl mgr" type="submit" value="Submit">Done</button>
          </div>
        </form>
      </div>
    </div>
    <div><br></div>
  </mat-tab>
  <mat-tab label="NAIL&HAIR">
    <div><br></div>
    <div class="lefty centered">
      <div>sort:</div>
      <button mat-stroked-button class="mgl" (click)="All()">all</button>
    </div>
    <div>
      <div><br></div>
      <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
        <div class="programs">
          <div class="course">Nail & Hair Treatments</div>
          <div><br></div>
          <ul class="w3-ul">
            <div *ngFor="let AOProgram of AOProgram; let i=index">
              <li class="w3-bar centered">
                <input class="w3-left w3-bar-item mgr" name="program" type="checkbox" [value]="AOProgram.value"
                       (change)="onCheckboxChange($event)"/>
                <div class="w3-bar-item onl">
                  <span class="w3-large black">{{AOProgram.pname}}</span><br>
                  <span class="gray">{{AOProgram.about}}</span>
                </div>
                <div class="col">
                  <div class="w3-right w3-bar-item">
                    <div class="row centered mgr">
                      <div class="col wide">
                        <span class="w3-large black">{{AOProgram.time}}</span><br>
                        <span class="gray">{{AOProgram.amount}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <div>
                <mat-divider></mat-divider>
              </div>
            </div>
          </ul>
        </div>
        <div><br></div>
        <div class="centered righty">
          <button mat-flat-button class="bbbb" type="submit" value="Reset">Reset</button>
          <button mat-flat-button class="bbbb mgl mgr" type="submit" value="Submit" [mat-dialog-close]="data" cdkFocusInitial>Done</button>
        </div>
      </form>
    </div>
    <div><br></div>
  </mat-tab>
</mat-tab-group>








<!--<mat-divider></mat-divider>-->
<!--<div><br></div>-->
<!--<div class="righty">-->
<!--  <div>-->
<!--    <span class="font">SUB TOTAL<span class="font1"> 0.0 THB </span></span>-->
<!--  </div>-->
<!--</div>-->
<!--<div><br></div>-->
<!--<mat-divider></mat-divider>-->
<!--<ul>-->
<!--  <li class="w3-bar" *ngFor="let p of childPosts">-->
<!--    <span onclick="this.parentElement.style.display='none'" class="w3-left w3-bar-item">-->
<!--      <button mat-icon-button aria-label="add icon">-->
<!--        <mat-icon>cancel</mat-icon>-->
<!--      </button>-->
<!--    </span>-->
<!--    <div class="w3-bar-item">-->
<!--      <span class="w3-large">Back & Shoulder</span><br>-->
<!--      <span>30 Min</span>-->
<!--    </div>-->
<!--    <div class="col">-->
<!--      <div class="w3-right w3-bar-item">-->
<!--        <span class="w3-large">350 THB</span><br>-->
<!--        <span>30 Min</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </li>-->
<!--</ul>-->
