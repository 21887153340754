export const bodyTh = {
  title: "BODY MASSAGE",
  title2: "BODY Treatments",
  coursesMassage: [
    {
      course: "JET-LAG RECOVERY MASSAGE",
      cost: "60/90 นาที (1,440/1,920 บาท)",
      detail:
        "ปรับสมดุลให้กับระดับพลังงานภายในร่างกายด้วยโปรแกรมการนวดบำบัดสุดพิเศษนี้ ทรีตเมนต์ที่ถูกออกแบบมาเพื่อช่วยบรรเทาอาการเหนื่อยล้าและความเครียดจากไฟลท์บินทางไกล เทคนิคการนวดแบบพิเศษจะช่วยให้ท่านผ่อนคลาย นำท่านสู่ผลลัพธ์ที่ช่วยปรับเวลาร่างกายและปลุกพลังให้รู้สึกมีชีวิตชีวามากขึ้น",
    },
    {
      course: "AROMA THERAPY MASSAGE",
      cost: "60/90 นาที (1,440/1,920 บาท)",
      detail:
        "การนวดที่นำเสนอประสบการณ์ความผ่อนคลายในทุกสัมผัส โดยการใช้น้ำมันเอสเซนเชียลออยล์เพื่อช่วยคลายความตึงเครียดทั้งทางร่างกายและจิตใจ การนวด กด รีด และคลึงจะช่วยสร้างบรรยากาศที่สงบและนำมาซึ่งความรู้สึกผ่อนคลายภายในอย่างแท้จริง",
    },
    {
      course: "SWEDISH THAI FUSION MASSAGE",
      cost: "60/90 นาที (1,800/2,400 บาท)",
      detail:
        "รูปแบบการนวดที่เน้นการใช้แรงกดไปยังกล้ามเนื้อชั้นลึก อาจรู้สึกเจ็บนิด ๆ แต่นำมาซึ่งการบรรเทาอาการเจ็บปวดได้อย่างเห็นผล ทรีตเมนต์นี้จะทำให้ท่านสัมผัสได้ถึงผลลัพธ์ ที่เปี่ยมไปด้วยประสิทธิภาพ ช่วยสลายพังผืดที่ฝังอยู่ตามกล้ามเนื้ออันเป็นต้นเหตุของอาการตึงหรือเมื่อยด้วยแรงกดและท่าบริหารยืดเหยียด โปรแกรมนี้จะสร้างการเปลี่ยนแปลงในร่างกายที่ท่านสามารถรู้สึกได้ในทันที",
    },
    {
      course: "HIMALAYAN SALT STONE MASSAGE",
      cost: "90/120 นาที (2,160/2,640 บาท)",
      detail:
        "ด้วยความชำนาญของเธอราพิสต์ ก้อนเกลือหิมาลายันอุ่นจะถูกใช้ในการนวดเพื่อขจัดความตึงเครียดของช่วงแขน ช่วงขา กล้ามเนื้อ ไปจนถึงเส้นเอ็น เมื่อก้อนเกลือสัมผัสและซึมเข้าสู่ผิว ความรู้สึกผ่อนคลาย ความสบายจะถือกำเนิดขึ้นในทันที ด้วยสรรพคุณในการกระตุ้นการไหลเวียนของระบบเลือด อีกทั้งช่วยคืนสมดุลให้กับระบบประสาท โปรแกรมนี้ยังถูกดีไซน์เพื่อแก้ปัญหาการนอนหลับยากอีกด้วย",
    },
    {
      course: "DEEP TISSUE MASSAGE",
      cost: "60/90 นาที (2,160/3,000 บาท)",
      detail:
        "ท่าในการนวดที่เปี่ยมไปด้วยพลัง ทรีตเมนต์นี้จะผสมผสานการนวดสวีดิชเข้ากับศาสตร์ของการกดจุดเพื่อแก้อาการและบรรเทาความเจ็บปวด นอกจากนี้ยังช่วยกระตุ้นการทำงานของกล้ามเนื้อในช่วงต้นขา หัวไหล่ แขน และหลัง เพื่อขจัดความรู้สึกเมื่อยล้าให้หมดไปด้วย",
    },
    {
      course: "TRADITIONAL THAI MASSAGE",
      cost: "60/90 นาที (1,200/1,560 บาท)",
      detail:
        "การนวดแผนไทยที่ไม่อาศัยน้ำมันหรือโลชั่น แต่ใช้เทคนิคของการบีบ กดจุด และการยืดเหยียดอันเป็นเอกลักษณ์ ช่วยผ่อนคลายกล้ามเนื้อที่ตึงแข็งและทิ้งความรู้สึกเบาสบายให้กับร่างกายของท่าน",
    },
    {
      course: "THAI HERBAL COMPRESSION ",
      cost: "90/120 นาที (1,800/2,400 บาท)",
      detail:
        "ทรีตเมนต์ที่ยึดแบบทางแผนไทยและใช้เทคนิคของการนวดแบบ Deep Tissue ผสานกับการใช้ลูกประคบสมุนไพรอุ่นร้อนเพื่อบรรเทาความเมื่อยล้าบริเวณช่วงไหล่และกล้ามเนื้อหลังได้อย่างน่าพึงพอใจ เมื่อลูกประคบสมุนไพรที่มีสรรพคุณทางการรักษาถูกนวดไปตามจุดต่าง ๆ คุณประโยชน์จะซึมเข้าถึงมัดกล้ามเนื้อชั้นใน ช่วยผ่อนคลายจุดที่แข็งและตึงได้ทั่วเรือนร่าง นำพาประสบการณ์แห่งความผ่อนคลายและความรู้สึกสบายให้กับท่าน",
    },
    {
      course: "ORIENTAL FOOT MASSAGE",
      cost: "60/90 นาที (1,200/1,560 บาท)",
      detail:
        "ทรีตเมนต์ที่ทรงพลัง ใช้ทฤษฎีของการนวดกดจุดมาใช้ โดยเธอราพิสต์จะกดจุดบนฝ่าเท้าที่สะท้อนกับการทำงานของอวัยวะและระบบต่าง ๆ ภายในร่างกาย เพื่อช่วยบรรเทาอาการ ผ่อนคลายความตึงเครียด และคืนความสมดุล",
    },
    {
      course: "ANTI-STRESS HEAD/BACK/SHOULDER MASSAGE",
      cost: "60/90 นาที (1,200/1,560 บาท)",
      detail:
        "เทคนิคพิเศษที่ถูกพิสูจน์แล้วว่าสามารถช่วยขจัดความตึงเครียดของร่างกายท่อนบนได้เป็นอย่างดี สลายส่วนที่รู้สึกตึงหรือแข็ง และช่วยเสริมความยืดหยุ่นให้กับกล้ามเนื้อด้วย หลักการคือการกระตุ้นระบบประสาทและการไหลเวียนของเลือดบริเวณหนังศีรษะ ช่วยบรรเทาอาการปวดศีรษะและให้ผลลัพธ์ที่ผ่อนคลายได้อย่างน่าประทับใจ",
    },
  ],

  coursesTreatments:[
    {
        course: "KHAO HOM BODY POLISH",
        cost: "60 นาที (1,800 บาท)",
        detail:
          "‘ข้าว’ คือรากฐานของวิถีชีวิตคนไทยและจิตวิญญาณของแต่ละมื้ออาหาร ในโปรแกรมนี้เราจึงนำ ‘ข้าวหอมมะลิ’ หรือประเภทของข้าวที่โด่งดังในระดับสากลในนามของ ‘แจสมิน ไรซ์’ มาทำเป็นบอดี้สครับที่อุดมไปด้วยโปรตีน ช่วยคืนความสดใสและบำรุงผิวผ่านขั้นตอนการผลัดเซลล์ผิวอย่างอ่อนโยน นอกจากข้าวหอมมะลิแล้ว สครับสูตรนี้ยังผสานกำลังกับน้ำมันรำข้าว สารสกัดจากส้มโอ เมล็ดดอกบัว และข้าวหอมนิล ช่วยเสริมให้ผิวมีสุขภาพที่ดี ดูผ่อนคลายและสดชื่นมากขึ้น"
    },
    {
        course: "TEA LEAF BODY SCRUB",
        cost: "60 นาที (1,800 บาท)",
        detail:
          "ด้วยคุณสมบัติในการลดการอักเสบและการต่อต้านเชื้อโรค สครับจากใบชาจึงเป็นที่นิยมมากสำหรับประสิทธิภาพในการขจัดเซลล์ผิวที่ตายไปแล้วและการรักษาผิวที่มีปัญหาสิว ผสมผสานทั้งสารสกัดจากชาเขียว สารสกัดจากเมล็ดลำไย ดอกคำฝอย และเมล็ดสตรอเบอร์รี่เข้าด้วยกัน สครับสูตรพิเศษนี้จะช่วยเปลี่ยนผิวของท่านให้เนียนนุ่ม สดใส และเปล่งปลั่งมากยิ่งขึ้น"
    },
    {
        course: "SILK BLISS BODY SCRUB",
        cost: "60 นาที (1,800 บาท)",
        detail:
          "โปรตีนไหมช่วยลดเลือนริ้วรอย โดยเพิ่มความเนียนกระชับ และในขณะเดียวกันก็ช่วยเพิ่มความยืดหยุ่นให้กับผิวได้อย่างดีเยี่ยม ทรีตเมนต์นี้โฟกัสไปยังการปรับเท็กซ์เจอร์ของผิวและการเติมความชุ่มชื่น ไหมสีทองผสมกับสารสกัดจากมะขามป้อม มัลเบอร์รี่ และใยบวบ จะช่วยเติมน้ำให้กับผิว ต้านริ้วรอยแห่งวัย และปกป้องผิวจากมลพิษได้เป็นอย่างดี นำมาซึ่งผลลัพธ์คือผิวที่เปล่งประกายอย่างเป็นธรรมชาติ"
    },
    {
        course: "ALGAE BODY SCRUB",
        cost: "60 นาที (1,800 บาท) ",
        detail:
          "สารสกัดจากสาหร่าย ถั่วหิมพานต์ กาบมะพร้าว อุดมไปด้วยวิตามินและแร่ธาตุที่พิสูจน์แล้วว่าสามารถสร้างเกราะป้องกันผิวชั้นดีได้ สาหร่ายอุดมไปด้วยสาร Anti-Oxidant ที่ช่วยต้านอนุมูลอิสระ ต้นเหตุของริ้วรอยและสภาพผิวที่ร่วงโรยก่อนวัย อีกทั้งยังช่วยกระตุ้นการสร้างคอลลาเจนในชั้นผิว เพิ่มความยืดหยุ่น และช่วยเสริมพลังต้านริ้วรอยให้ดีขึ้นอีกขั้น คืนผิวสุขภาพดีและกระชับให้กับท่าน"
    },
    {
        course: "WHITE ORCHID BODY MASK",
        cost: "60 นาที (1,800 บาท)",
        detail:
          "อุดมด้วยสารสกัดจากดอกกล้วยไม้ขาว ผงทองคำ k และซิลิกา บอดี้มาส์กสูตรนี้คือนวัตกรรมสุดเลิศที่เข้าจัดการเรื่องความกระจ่างใส ประกอบด้วยสารต้านอนุมูลอิสระ วิตามินรวม และเอนไซม์ที่สำคัญกับผิว ผลิตภัณฑ์จะช่วยเสกผิวที่เรียบเนียนและสีผิวที่สม่ำเสมอ ด้วยพลังแห่งพฤกษานานาพรรณที่ผ่านการพิสูจน์ทางวิทยาศาสตร์ ผิวของท่านจะดูสว่างและกระจ่างใสมากยิ่งขึ้นหลังจบทรีตเมนต์นี้"
    },
  ]
};
