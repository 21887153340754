export const expressEn ={
    title: "Express Spa",
    courses: [
      {
        course: "TENSION RELIEF MASSAGE	",
        cost: "30 min (850THB)",
        detail: "An intensive massage focused on the back, neck and shoulders that rapidly releases pain and tension. Ideal for those with little time on their hands.",
      },
      {
          course: "AYURVEDIC HEAD MASSAGE",
          cost: "30 min (850THB)",
          detail: "This holistic head massage is a healing, rejuvenating and thoroughly stimulating experience. Action on vital energy points on the face, upper back, shoulders and neck promotes high-level alertness and focused concentration.",
      },
      {
          course: "MINI FACIAL ",
          cost: "30 min (850THB)",
          detail: "A purposely-composed facial massage technique achieving a healthier, smoother complexion. ",
      },
    ],
}